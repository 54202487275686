import { Input } from "antd";
import React, { Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import { BsDashSquareDotted, BsPlusSquareDotted } from "react-icons/bs";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
const UpdateForm = ({
	HandleImageAnswer,
	problemsTypeSec,
	problemsType,
	problems,
	sectionIndex,
	handleCorrectchange,
	section,
	RemoveSection,
	addSection,
	AddQusFiled,
	RemoveQusFiled,
	heandalQuizinfochange,
	handleFromchange,
	handleFromAnswerchange1,
	handleFromAnswerchange,
	AddOp,
	rmve,
	removeImgOption,
	handleImageQuestchange,
  handleCorrectAnswerImage,
	AddImageQuest,
	rmveCAI,
	imgAnswer,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [showModalImg, setShowModalImg] = useState("");
	const { questions, section_name } = section;

	let checkMultipleCorrect = (event, idx, index, sectionIndex) => {
		// let temp = textAns
		// temp[sectionIndex][index][idx] = event.target.value
		// console.log(temp);

		handleCorrectchange(event, idx, index, sectionIndex);
	};
	// useEffect(()=>{
	//   console.log(questions, sectionIndex);

	// },[questions])
	return (
		<Fragment>
			<div className="flex justify-between align-items-center mb-5">
				<h5 className="fw-bold m-0 border-bottom border-2 border-dark p-2">
					Section - {sectionIndex + 1}
				</h5>
				<button
					type="button"
					class="btn btn-sm btn-danger shadow-sm"
					onClick={() => RemoveSection(sectionIndex)}
				>
					Remove Section{" "}
					<BsDashSquareDotted className="inline fs-6 mb-1 ml-2" />
				</button>
			</div>

			<div>
				<Form.Label className="fs-6 fw-bold w-100">
					Section Name <span className="text-danger">*</span>{" "}
				</Form.Label>
				<input
					type="text"
					className="border border-2 border-secondary p-2 rounded mt-2 mb-3 w-50"
					placeholder="Section Name"
					name="section_name"
					value={section_name}
					onChange={(event) => heandalQuizinfochange(event, sectionIndex)}
				/>
			</div>
			{questions?.map((form, index) => (
				<div className="mb-3 mt-4" key={index}>
					{/* {console.log(form.answers.filter((e)=> e.is_correct == true).length)} */}
					<div className="d-flex justify-between align-items-center">
						<h3 className="p-2 border-bottom border-2 border-dark fs-6 fw-bold mb-0">
							Question - {index + 1}
						</h3>
						<div className="flex justify-end my-3">
							<button
								type="button"
								class="btn btn-sm btn-danger shadow-sm"
								onClick={() => RemoveQusFiled(sectionIndex, index)}
							>
								Remove Question{" "}
								<BsDashSquareDotted className="inline fs-6 mb-1 ml-2" />
							</button>
						</div>
					</div>
					{problems.map((e, i) =>
						e === index && sectionIndex === problemsTypeSec[i] ? (
							<p className="alert alert-danger">
								{problemsType[i] === "Question type ?"
									? " Select Question Type For This Question"
									: problemsType[i] === false
									? "Select One Correct Answer for This Question"
									: "Set Correct Answer for this Question"}{" "}
							</p>
						) : null
					)}
					<div className="flex mt-3">
						<select
							className="select rounded shadow-sm border border-secondary border-1 w-50 p-2 me-1 mb-4 shadow-md"
							name="question_type"
							onChange={(event) => handleFromchange(event, index, sectionIndex)}
							value={form.question_type}
						>
							<option defaultValue={true}>Question type ?</option>
							<option>text</option>
							<option>code</option>
						</select>
						<select
							className="select rounded shadow-sm border border-secondary border-1 ms-1 p-2 w-50 mb-4 shadow-md"
							name="answer_type"
							onChange={(event) => handleFromchange(event, index, sectionIndex)}
							value={form.answer_type}
						>
							<option defaultValue={true}>Answer type ?</option>
							<option>text</option>
							<option>image</option>
							<option>code</option>
						</select>
					</div>
					{/* <input type="text" placeholder={`Question`} className="input p-2  input-bordered w-100 mb-4 shadow-md" name='body' onChange={event => handleFromchange(event, index)}
          /> */}
					<div className="d-flex align-items-center justify-content-between mb-3">
						<textarea
							rows={`${form.body.split("\n").length}`}
							//cols="90"
							type="text"
							placeholder={`Question`}
							className="input border border-1 shadow-sm border-secondary p-2 rounded w-75"
							name="body"
							value={form.body}
							onChange={(event) => handleFromchange(event, index, sectionIndex)}
						/>
						{/*<h6 class="mb-0">Include Images in Question</h6>*/}
						<div>
							<button
								type="button"
								class="btn btn-sm btn-outline-secondary"
								onClick={() => AddImageQuest(sectionIndex, index)}
							>
								Add image in question{" "}
								<BsPlusSquareDotted className="inline fs-6 mb-1 ml-2" />
							</button>
						</div>
					</div>
					<p style={{ color: "red" }}>
						{form.answers.filter((e) => e.is_correct == true).length > 1
							? "SElECT ONLY ONE OPTION AS TRUE."
							: ""}
					</p>
					{form?.question_images.map((img, imQuestIndex) => {
						return (
							<Fragment key={imQuestIndex}>
								<div className="d-flex mb-3">
									{img.img ? (
										<span onClick={() => setShowModalImg(img.img)}>
											<Zoom>
												<img alt="" src={img.img} width="200" height="120" />
											</Zoom>
										</span>
									) : (
										<Input
											type="file"
											placeholder="Image Question"
											className="input p-2 rounded input-bordered w-full  drop-shadow-md  border-secondary"
											name={"question_image"}
											onChange={(event) =>
												handleImageQuestchange(
													event,
													imQuestIndex,
													index,
													sectionIndex
												)
											}
										/>
									)}
									<button
										type="button"
										class="btn"
										onClick={() =>
											removeImgOption(imQuestIndex, index, sectionIndex)
										}
									>
										<i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
									</button>
								</div>
							</Fragment>
						);
					})}
					<h6 className="mt-5 mb-3 fw-bold">Options</h6>
					{form?.answer_type === "text" && (
						<>
							{form?.answers?.map((value, idx) => {
								// console.log(value);
								return (
									<div key={idx} className="flex align-center mt-3">
										<select
											className="select  border border-1 shadow-md px-1"
											name={"is_correct"}
											onChange={(event) =>
												checkMultipleCorrect(event, idx, index, sectionIndex)
											}
										>
											{value.is_correct ? (
												<Fragment>
													<option>false</option>
													<option selected>true</option>
												</Fragment>
											) : (
												<Fragment>
													<option defaultValue={true}>false</option>
													<option>true</option>
												</Fragment>
											)}
										</select>
										<textarea
											rows={`${value.answer.split("\n").length}`}
											type="text"
											placeholder="option"
											className="input p-2 option_inp  input-bordered w-100 shadow-md ms-2 border-secondary rounded"
											name={"answer"}
											value={value.answer}
											onChange={(event) =>
												handleFromAnswerchange(event, idx, index, sectionIndex)
											}
										/>
										<button
											type="button"
											class="btn"
											onClick={() => rmve(index, idx, sectionIndex)}
										>
											<i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
										</button>
									</div>
								);
							})}
						</>
					)}
					{form?.answer_type === "image" && (
						<>
							{form?.answers?.map((value, idx) => {
								return (
									<>
										<div className="d-flex align-items-center mt-2" key={idx}>
											<select
												className="select align-self-center py-2 mt-2 me-3  border border-1 shadow-md px-1"
												name={"is_correct"}
												onChange={(event) =>
													handleCorrectchange(event, idx, index, sectionIndex)
												}
											>
												{value.is_correct ? (
													<Fragment>
														<option>false</option>
														<option selected>true</option>
													</Fragment>
												) : (
													<Fragment>
														<option defaultValue={true}>false</option>
														<option>true</option>
													</Fragment>
												)}
											</select>
											{!value._id ? (
												<Input
													type="file"
													placeholder="option"
													className="input p-2 option_inp  input-bordered w-100 mb-4 shadow-md ms-2 border-secondary rounded"
													name={"answer"}
													onChange={(event) =>
														handleFromAnswerchange1(
															event,
															idx,
															index,
															sectionIndex
														)
													}
												/>
											) : (
												<></>
											)}

											{value._id ? (
												<span onClick={() => setShowModalImg(value.answer)}>
													<Zoom>
														<img alt="" src={value.answer} width="200" />
													</Zoom>
												</span>
											) : (
												<></>
											)}
											<button
												type="button"
												class="btn"
												onClick={() => rmve(index, idx, sectionIndex)}
											>
												<i class="fa-solid fa-trash me-2 text-danger fs-4"></i>
											</button>
										</div>
									</>
								);
							})}
						</>
					)}
					{form?.answer_type === "code" && (
						<>
							{form?.answers?.map((value, idx) => {
								return (
									<div key={idx} className="flex align-center mt-2">
										<select
											className="select  border border-1 shadow-md px-1 h-[46px]"
											name={"is_correct"}
											onChange={(event) =>
												handleCorrectchange(event, idx, index, sectionIndex)
											}
										>
											{value.is_correct ? (
												<Fragment>
													<option>false</option>
													<option selected>true</option>
												</Fragment>
											) : (
												<Fragment>
													<option defaultValue={true}>false</option>
													<option>true</option>
												</Fragment>
											)}
										</select>
										<textarea
											rows={`${value.answer.split("\n").length}`}
											cols="90"
											type="text"
											placeholder="option"
											className="input p-2 option_inp  input-bordered w-100 mb-4 shadow-md mb-0 ms-2"
											name={"answer"}
											value={value.answer}
											onChange={(event) =>
												handleFromAnswerchange(event, idx, index, sectionIndex)
											}
										/>

										<button
											type="button"
											class="btn"
											onClick={() => rmve(index, idx, sectionIndex)}
										>
											<i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
										</button>
									</div>
								);
							})}
						</>
					)}

					<div className="mt-4 mb-4">
						<button
							type="button"
							class="btn btn-sm btn-outline-secondary"
							onClick={() => AddOp(sectionIndex, index)}
						>
							Add more option{" "}
							<BsPlusSquareDotted className="inline fs-6 mb-1 ml-2" />
						</button>
					</div>

					{form?.answers.length >= 2 && (
						<Fragment>
							<Form.Label className="fs-6 mt-3 mb-2 fw-bold">
								Correct Answer Description
							</Form.Label>
							<textarea
								rows="2"
								cols="90"
								type="text"
								value={form.correct_answer_description}
								placeholder={`Enter Correct Answer Description ...`}
								className="input p-2  border border-1 w-100 mb-1 shadow-sm border-secondary "
								name="correct_answer_description"
								onChange={(event) =>
									handleFromchange(event, index, sectionIndex)
								}
							/>
							<Form.Label className="fs-6 mt-3 mb-2 fw-bold">
								Correct Answer Image
							</Form.Label>
							{form.correct_answer_image?._id &&
							form.correct_answer_image.img.includes(
								"https://atg-test-s3.s3.ap-south-1.amazonaws.com"
							) ? (
								<div>
									<span
										onClick={() =>
											setShowModalImg(form.correct_answer_image?.img)
										}
									>
										<Zoom>
											<img
												alt=""
												src={form.correct_answer_image?.img}
												width="200"
											/>
										</Zoom>
									</span>

									<button
										type="button"
										class="btn"
										onClick={() => rmveCAI(index, sectionIndex)}
									>
										<i class="fa-solid fa-trash me-2 text-danger fs-4 pb-3"></i>
									</button>
								</div>
							) : (
								<Input
									type="file"
									placeholder="option"
									className="input p-2 input-bordered w-100 mb-4 shadow-md border-secondary rounded"
									name={"correct_answer_image"}
									onChange={(event) =>
										handleCorrectAnswerImage(event, index, sectionIndex)
									}
								/>
							)}
						</Fragment>
					)}
				</div>
			))}

			<div className="flex justify-end mt-4 pb-4">
				<div className="flex flex-column">
					<button
						type="button"
						className="btn btn-sm btn-primary mb-5  px-3"
						onClick={() => AddQusFiled(sectionIndex)}
					>
						Add Question{" "}
						<BsPlusSquareDotted className="inline fs-6 mb-1 ml-2" />
					</button>
					<button
						type="button"
						class="btn btn-sm  btn-outline-secondary text-dark mb-4 px-3"
						onClick={addSection}
					>
						Add Section <BsPlusSquareDotted className="inline fs-6 mb-1 ml-2" />
					</button>
				</div>
			</div>
		</Fragment>
	);
};

export default UpdateForm;
