import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const QuizApi = createApi({
    reducerPath: 'QuizApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
    }),

    endpoints: (builder) => ({
        addevaluate: builder.mutation({ query: (ans) => ({ url: `questionBank/evaluate`, method: 'POST', body: ans }) }),
        addAttempt: builder.mutation({
            query: ({ type, details }) => ({
                url: `${type}/add`, method: 'POST',
                body: details
                // {
                //     question_bank_id: questionBankId,
                //     "user_id": userId,
                //     score: score,
                //     course_id: courseId ? courseId : null,
                //     assessment_type: !courseId ? "standalone" : "atg"

                // }
            })
        }),
    })
})

export const { useAddAttemptMutation, useAddevaluateMutation } = QuizApi;
