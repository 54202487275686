import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BiHome } from "react-icons/bi";
import { GiReturnArrow } from "react-icons/gi";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useAddAssessmentMutation } from "../../redux/Assignment/AssignmentApi";

const AddFrom = () => {
  const [AllQuestions, setQuestions] = useState([]);
  const [inputFilds, setinputFilds] = useState([]);
  const premas = useParams();
  const [createLastObject, responseInfo] = useAddAssessmentMutation()
  console.log(responseInfo);

  useEffect(() => {
    let url = process.env.REACT_APP_API_BASE_URL;
    fetch(`${url}questionbank?${premas.id}`)
      .then((res) => res.json())
      .then((data) => setQuestions(data));
  }, []);

  console.log(AllQuestions);

  const heandalFromchange = (event, index) => {
    let data = [...inputFilds];
    data[index][event.target.name] = event.target.value;
    setinputFilds(data);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let Allquestions = [];
    for (let i = 0; i < inputFilds.length; i++) {
      const oneQuestion = {
        question_type: inputFilds[i].question_type,
        answer_type: inputFilds[i].answer_type,
        body: inputFilds[i].body,
        answers: [
          {
            answer1: inputFilds[i].answer1,
            is_correct1: inputFilds[i].is_correct1 ? true : false,
          },
          {
            answer2: inputFilds[i].answer2,
            is_correct2: inputFilds[i].is_correct2 ? true : false,
          },
          {
            answer3: inputFilds[i].answer3,
            is_correct3: inputFilds[i].is_correct3 ? true : false,
          },
          {
            answer4: inputFilds[i].answer4,
            is_correct4: inputFilds[i].is_correct4 ? true : false,
          },
        ],
        section_id: "1",
      };
      Allquestions.push(...Allquestions, oneQuestion);
    }

    const LastObject = {
      time_limit: 60,
      attempts_allowed: 1,
      course_id: "qwerty",
      section_id: "qwerty",
      type: "mcq",
      questions: Allquestions,
    };
    let url = process.env.REACT_APP_API_BASE_URL;
    createLastObject(LastObject)
    // axios({
    //   method: "post",
    //   url: `${url}questionBank/add`,
    //   data: LastObject,
    // })
    //   .then(function (response) {
    //     // handle success
    //     setinputFilds([
    //       {
    //         question_type: "",
    //         answer_type: "",
    //         body: "",
    //         answer1: "",
    //         is_correct1: "",

    //         answer2: "",
    //         is_correct2: "",

    //         answer3: "",
    //         is_correct3: "",

    //         answer4: "",
    //         is_correct4: "",
    //       },
    //     ]);
    //     toast.success("Assessments Are posted");
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     toast.error("There is a problem");
    //   });
  };

  return (
    <div className="assignment">
      <div className="ass-header mous ">
        <h4>
          <BiHome className="ass-title mb-[6px]" /> - Admin panel - Assessments
          - Adding Assessments
        </h4>
        <div>
          <Link to="/admin">
            <button className="assignment-btn">
              Back to Assessments{" "}
              <GiReturnArrow className="inline mb-[4px] font-bold" />
            </button>
          </Link>
        </div>
      </div>
      <div className="assignment-adding-div mx-auto px-[24px] drop-shadow-2xl">
        <div className="flex justify-between">
          <h5 className="text-[19px] font-semibold py-[20px]">
            Add Assessments
          </h5>
          {inputFilds.length ? (
            <>
              <button
                onClick={onSubmit}
                className="assignment-add-btn my-4 text-white bg-[#2da44e]"
                type="submit"
              >
                Save Assessments
                <MdOutlineFileDownloadDone className="inline text-[18px] mb-1 ml-2" />
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        <form onSubmit={onSubmit}>
          {AllQuestions?.map((form, index) => (
            <div className="mt-[20px] mb-[25px]" key={index}>
              <div className="flex">
                <select
                  className="select rounded-none select-bordered w-[49%] mr-1 mb-[25px] drop-shadow-md"
                  name="question_type"
                  onChange={(event) => heandalFromchange(event, index)}
                  value={form.question_type}
                >
                  <option selected>Qus type ?</option>
                  <option>text</option>
                </select>
                <select
                  className="select rounded-none select-bordered ml-1 w-[49%] mb-[25px] drop-shadow-md"
                  name="answer_type"
                  onChange={(event) => heandalFromchange(event, index)}
                  value={form.answer_type}
                >
                  <option selected>Answer type ?</option>
                  <option>text</option>
                  {/* <option >Img</option>
                                <option >code</option> */}
                </select>
              </div>
              <input
                type="text"
                placeholder={`Question`}
                className="input rounded-none input-bordered w-full mb-[25px] drop-shadow-md"
                name="body"
                onChange={(event) => heandalFromchange(event, index)}
              />
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};

export default AddFrom;