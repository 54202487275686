import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const CollectionsApi = createApi({
    tagTypes: ['collections'],
    reducerPath: 'CollectionsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
    }),

    endpoints: (builder) => ({
        getCollections: builder.query({ query: ({ page, limit, search }) => `/collections/?page=${page}&limit=${limit}&collection_name=${search}`, providesTags: ['collections'] }),
        getCollectionsById: builder.query({ query: ({ id }) => `/collections?id=${id}`, providesTags: ['collections'] }),
        addAssessment: builder.mutation({ query: (payload) => ({ url: 'collections/add', method: 'POST', body: payload }), invalidatesTags: ['collections'] }),
        updateCollection: builder.mutation({ query: ({ _id, collection }) => ({ url: `collections/${_id}/update`, method: 'PUT', body: collection }), invalidatesTags: ['collections'] }),
        deleteCollection: builder.mutation({ query: (_id) => ({ url: `collections/${_id}/delete`, method: 'DELETE' }), invalidatesTags: ['collections'] }),
    })
})

export const { useGetCollectionsQuery, useGetCollectionsByIdQuery, useAddAssessmentMutation, useDeleteCollectionMutation, useUpdateCollectionMutation } = CollectionsApi;
