import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export let userAttempts = createAsyncThunk('filterTempts/attempts', async ({ type, idtype, questionBankId, userId }, thunckAPI) => {
    let { rejectWithValue } = thunckAPI
    try {
        let { data } = await axios.get(`${baseUrl}${type}/?user_id=${userId}&${idtype}=${questionBankId}`);
        return data?.attempts
    } catch (error) {
        return rejectWithValue(error.message)
    }
})
let resultAttempts = createSlice({
    name: "attempts",
    initialState: { attempts: [], tempLength: 0, isLoading: false, error: null },
    extraReducers: {
        // user tempts
        [userAttempts.fulfilled]: (state, action) => {
            state.attempts = action.payload;
            state.tempLength = state.attempts.length
            state.isLoading = false;
        },
    }
})

export default resultAttempts.reducer;