import React, { useEffect, useRef, useState } from 'react'
import { advices } from '../constants/constants'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useDispatch, useSelector } from "react-redux";
import { setAttempts } from '../redux/features/quizSlice'
import axios from 'axios';
import { userAttempts } from '../redux/Attempts/resultAttemptsApi.js';
import { getQues } from '../redux/Assignment/getQuesApi';

const Result = ({ resultNumber, questionBankId, courseId, userId, total_correct, total_questions, graded }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const [advice, setAdvice] = useState('')
  const { attempts } = useSelector((state) => state.quizOptions)
  let { quest } = useSelector((state) => state.quest);
  const { tempLength } = useSelector((state) => state.resultAttempts)
  // const [atmtLen, setAtmtLen] = useState(0)
  const [subAdvice, setSubAdvice] = useState('')
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  // const [per, setPer] = useState(0)
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  const resRef = useRef()

  // let url = process.env.REACT_APP_API_BASE_URL

  // useEffect(() => {
  // axios.get(`${url}attempt/?user_id=${userId}&question_bank_id=${questionBankId}`).then((res) => {
  //   console.log(res.data)
  //   const atLength = res?.data?.attempts?.length
  //   setAtmtLen(atLength)
  // }).catch((err) => console.log(err))

  // }, [])

  useEffect(() => {
    dispatch(getQues({ type: courseId === undefined ? `assessmentTemplate` : `questionbank`, id: questionBankId }));
    dispatch(userAttempts({ type: courseId !== undefined ? `attempt` : `standaloneAttempt`, idtype: courseId === undefined ? `assessment_template_id` : `question_bank_id`, questionBankId, userId }))
    console.log('in rsult',total_correct, total_questions,resultNumber,graded);
    // const mainTag = document.querySelector('.semicircle-percent-value').innerHTML.split('%')[0]
    if(graded){
      document.querySelector('.semicircle-percent-value').innerHTML = `${total_correct} / ${total_questions} <br/> ${resultNumber}%`
    }
    else{
      document.querySelector('.semicircle-percent-value').innerHTML = `${total_correct} <span  class="semicircle_total_questions">/ ${total_questions}</span>`
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return (() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  console.log("resultQuest", quest)
  useEffect(() => {
    if (resultNumber >= 90 && resultNumber <= 100) {
      setAdvice(advices[0].advice)
      setSubAdvice(advices[0].subAdvice)
    } else if (resultNumber >= 80 && resultNumber < 90) {
      setAdvice(advices[1].advice)
      setSubAdvice(advices[1].subAdvice)
    } else if (resultNumber >= 70 && resultNumber < 80) {
      setAdvice(advices[2].advice)
      setSubAdvice(advices[2].subAdvice)
    } else if (resultNumber >= 60 && resultNumber < 70) {
      setAdvice(advices[3].advice)
      setSubAdvice(advices[3].subAdvice)
    } else if (resultNumber >= 50 && resultNumber < 60) {
      setAdvice(advices[4].advice)
      setSubAdvice(advices[4].subAdvice)
    } else {
      setAdvice(advices[5].advice)
      setSubAdvice(advices[5].subAdvice)
    }
  }, [resultNumber])


  // 13.126.49.199
  return (
    <div className="container-fluid sc_main_col mmc">
      <div className="flex justify-end w-[100%] absolute top-4 right-8">
        {/* {courseId === undefined ? <button className='btn btn-primary' disabled={quest.attempts_allowed <= tempLength}><a href={`/standalone/${questionBankId}/${userId}`} className="text-white">Retake</a></button> : <button className='btn btn-primary' disabled={quest.attempts_allowed <= tempLength}><a href={`//${questionBankId}/${courseId}/${userId}`} className="text-white">Retake</a></button>}*/}
        {courseId === undefined ? <button className='btn btn-primary' ><a href={`/standalone/${questionBankId}/${userId}`} className="text-white">Retake</a></button> : <button className='btn btn-primary' ><a href={`/${questionBankId}/${courseId}/${userId}`} className="text-white">Retake</a></button>}
      </div>
      <div className="row ">
        <div className="result col mmc">
          <div className="result_progress mmc" ref={resRef}>
            <SemiCircleProgressBar
              percentage={resultNumber}
              id='main'
              ref={resRef}
              showPercentValue
              background={resultNumber < 30 ? `#d536369a` : resultNumber >= 31 && resultNumber <= 55 ? '#ffebd7' : '#9FDFBE'}
              diameter={screenSize.dynamicWidth <= 572 ? 320 : 570}
              strokeWidth={20}
              stroke={resultNumber < 30 ? `#fc0000` : resultNumber >= 31 && resultNumber <= 55 ? '#ffa74e' : '#00B879'}
            />


            <div className="adv  mmc">
              <h4 className="adv_text mt-4">
                {advice && advice}
              </h4>
              <h4 className="sub_adv_text">
                {subAdvice && subAdvice}
              </h4>

              {!location.pathname.includes('/start') ? <div className="share_score_btn mt-5">
                <Link to={`/`} className="share_link ml-1">
                  Share your score
                </Link>
              </div> : null}
            </div>

            {!location.pathname.includes('/start') ? <div className="back_to_home mmc mt-3 cursor-pointer">
              <p onClick={() => navigate(-1)} className="back_home_link cursor-pointer">
                Back to home
              </p>
            </div> : null}

            <div className="message_to_user mmc mt-5">
              <h4 className="message_to_user_text mmc">
                We have already shared your score with the creator.
              </h4>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Result