import { Table } from "react-bootstrap";
import React, { Fragment, useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

const UsersDataTable = ({
  usersData,
  page,
  setpage,
  setlimit,
  lenght,
  limit,
  setlength
}) => {
  let navigate = useNavigate();

  const handleLimit = (e) => {
    e.preventDefault();
    setpage(1);
    setlimit(e.target.value);
  };

  const pagin = () => {
    const visiblePages = 5; // Number of visible page numbers
    const pages = [];

    // Calculate the range of page numbers to show
    let startPage = Math.max(1, page - Math.floor(visiblePages / 2));
    let endPage = Math.min(lenght, startPage + visiblePages - 1);

    // Adjust the range if there are not enough pages
    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setpage(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return pages;
  };

  console.log(lenght);

  // console.log(countAssessTemplate(1));
  return (
    <Fragment>
      <Table responsive className="border border-2 text-center h-100" hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {usersData.map((e, i) => (
            <tr key={`user${i}`}>
              <td>{e.email}</td>
              <td style={{ display: "flex", justifyContent: "center" }}>
                {/* <button
                  style={{ padding: "2px", border: "1px solid black" }}
                  onClick={() => navigate(`/admin/UsersReport/${e._id}`)}
                >
                  Check Report
                </button> */}
                <button
                        className="btn"
                        onClick={() =>
                          navigate(`/admin/UsersReport/${e._id}`)
                        }
                      >
                        <i style={{paddingRight:'10px'}} className="fa-solid fa-list-check"></i>Check Report
                      </button>
               
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* {!generatedLinks && ( */}
      <div className="row">
        <div className="col-md-11 col-xs-12 justify-content-start">
          <Pagination size="xs">
            <Pagination.First onClick={() => setpage(1)} />
            <Pagination.Prev
              onClick={() => setpage(Math.max(1, page - 1))}
              disabled={page <= 1}
            />
            {pagin()}
            <Pagination.Next
              onClick={() => setpage(Math.min(lenght, page + 1))}
              disabled={page >= lenght}
            />
            <Pagination.Last onClick={() => setpage(lenght)} />
          </Pagination>
        </div>
        <div className="col-md-1 col-xs-12 justify-content-end">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => handleLimit(e)}
            className="select-box"
            value={limit}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </Form.Select>
        </div>
      </div>
      {/* )} */}
    </Fragment>
  );
};

export default UsersDataTable;
