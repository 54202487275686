import { configureStore } from "@reduxjs/toolkit";

import { AssignmentApi } from "./Assignment/AssignmentApi";
import quizOptionReducer from "./features/quizSlice";
import { CollectionsApi } from "./Collections/CollectionsApi";
import { AssessmentApi } from "./Assessment/AssessmentApi";
import assessReducer from "./Assessment/GenerateAssessApi";
import questReducer from "./Assignment/getQuesApi";
import temptsReducer from "./Attempts/AttemptsApi";
import resultAttemptsReducer from './Attempts/resultAttemptsApi'
import { QuizApi } from "./Quiz/QuizApi";
import { AssignmentReportApi } from "./UsersReport/AssesmentReports";
export const store = configureStore({
    reducer: {
        [AssignmentApi.reducerPath]: AssignmentApi.reducer,
        [CollectionsApi.reducerPath]: CollectionsApi.reducer,
        [AssignmentReportApi.reducerPath]: AssignmentReportApi.reducer,
        [AssessmentApi.reducerPath]: AssessmentApi.reducer,
        [QuizApi.reducerPath]: QuizApi.reducer,
        quizOptions: quizOptionReducer,
        attempts: temptsReducer,
        quest: questReducer,
        assessment: assessReducer,
        resultAttempts: resultAttemptsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(AssignmentApi.middleware)
            .concat(CollectionsApi.middleware)
            .concat(AssignmentReportApi.middleware)
            .concat(AssessmentApi.middleware)
            .concat(QuizApi.middleware)
});

// middleware : (getDefaultMiddleware)=>getDefaultMiddleware().concat(shazamCoreApi.middleware),
