import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
export default function Header({ title, secTitle, path,buttons }) {
    return (
        <Fragment>            
            {secTitle &&
                <div className="flex justify-between align-items-md-center px-5 py-3 fs-5 border-bottom">
                    <div className='d-flex align-items-center text-black'>
                        <Link to={path}><i class="fa-solid fa-arrow-left me-3 text-black"></i></Link>
                        <p className=' fw-bold  mb-0'>{secTitle}</p>
                    </div>
                    {buttons}
                </div>
            }
        </Fragment >
    )
}
