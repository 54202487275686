
import React, { useState, Fragment } from 'react';
import { BiHome } from 'react-icons/bi';
import { GiReturnArrow } from 'react-icons/gi';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { MdOutlineFileDownloadDone } from 'react-icons/md';
import { AiOutlineMinusSquare } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import { Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { useCreateAssessmentMutation } from '../../redux/Assessment/AssessmentApi';


const AddAssignment = () => {
    const [addAssessment] = useCreateAssessmentMutation();
    const [inputFilds, setinputFilds] = useState([]);
    const [quizinfo, setquizinfo] = useState([{
        "time_limit": 1,
        "pass_score": 1,
        "title": "",
        "description": "2",
        "instructions": [],
        "attempts_allowed": 1,
        "course_id": "",
        "section_id": "",
    }]);

    const heandalQuizinfochange = (event) => {
        let data = [...quizinfo];
        data[0][event.target.name] = event.target.value;
        setquizinfo(data)
    }

    const heandalFromchange = (event, index) => {
        let data = [...inputFilds];
        data[index][event.target.name] = event.target.value;
        setinputFilds(data)
    }

    const handleFromAnswerchange = (event, answerIndex, qusIndex) => {
        if (inputFilds[qusIndex].answer_type === 'image') {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                const string = reader.result.toString();
                let data = [...inputFilds];
                data[qusIndex].answers[answerIndex].answer = string;
                setinputFilds(data)
            }
            reader.readAsDataURL(file)
        }
        else {
            let data = [...inputFilds];
            data[qusIndex].answers[answerIndex].answer = event.target.value
            setinputFilds(data)
        }
    }

    const handleFromAnswerChange1 = (event, answerIndex, qusIndex) => {
        let data = [...inputFilds];
        if (event.target.value === 'true') {
            data[qusIndex].answers[answerIndex].is_correct = true
        }
        else {
            data[qusIndex].answers[answerIndex].is_correct = false
        }
        setinputFilds(data)
    }

    const AddQusFiled = () => {
        const Newqus = {
            "question_type": "",
            "answer_type": "",
            "body": "",
            "answers": [],
            "section_id": '',
            "createdAt": Date.now(),
            "__v": 0
        }
        const newFiled = [...inputFilds, Newqus]
        setinputFilds(newFiled)
    }
    const RemoveQusFiled = (index) => {
        const newArray = [...inputFilds];
        newArray.splice(index, 1)
        setinputFilds(newArray)
    }
    const AddOptions = (index) => {
        if (inputFilds[index].answer_type) {
            const data = [...inputFilds]
            const newOption = {
                "answer": "",
                "is_correct": false,
            }
            data[index].answers.push(newOption)
            setinputFilds(data)
        }
        else {
            toast.info('Pls Select The Option Type')
        }
    }

    const removeOption = (questionIndex, answerIndex) => {
        const data = [...inputFilds]
        const items = data[questionIndex].answers.filter((answer, idx) => {
            return idx !== answerIndex
        })

        data[questionIndex].answers = items
        setinputFilds(data)
    }

    const onSubmit = event => {
        event.preventDefault()

        const LastObject = {
            "time_limit": quizinfo[0].time_limit,
            "attempts_allowed": quizinfo[0].attempts_allowed,
            "course_id": quizinfo[0].course_id,
            "section_id": quizinfo[0].section_id,
            "pass_score": quizinfo[0].pass_score,
            "title": quizinfo[0].title,
            "description": quizinfo[0].description,
            "instructions": quizinfo[0].instructions,
            "type": "mcq",
            "questions": inputFilds,
        }
        addAssessment(LastObject)
            .unwrap()
            .then((res) => {
                setinputFilds([{
                    "question_type": "",
                    "answer_type": "",
                    "body": "",
                    "answers": [],
                    "section_id": '',
                    "createdAt": Date.now(),
                    "__v": 0
                }])
                toast.success('Assessments Are posted')
                console.log(res)
            })
            .catch((error) => {
                console.log(error);
                toast.error('There is a problem')
            });

    };
    return (
        <div className='assignment' >
            <div className='ass-header mous '>
                <h4><BiHome className='ass-title mb-2' /> - Admin panel - Assessments - Adding Assessments</h4>
                <div>
                    <Link to='/admin'><button className="assignment-btn text-black">Back to Assessments <GiReturnArrow className='inline mb-[4px] font-bold' /></button></Link>
                </div>
            </div>
            <div className='assignment-adding-div mx-auto px-3 drop-shadow-2xl'>
                <div className='flex justify-between'>
                    <h5 className='text-[19px] font-semibold py-3'>Add Assessment</h5>
                    {
                        inputFilds.length ? <><Button type="primary" className="addSub" onClick={onSubmit} >Save Assessments<MdOutlineFileDownloadDone className='inline text-[18px] mb-1 ms-2' /></Button></> : ''
                    }
                </div>

                <div className='needs-filed'>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Time Limit ( minutes )</span>
                        <input type="number" placeholder="Time in min" className="info-input" name='time_limit' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Pass Score  ( in percentage out of 100 ) </span>
                        <input type="number" placeholder="pass score" className="info-input" name='pass_score' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Title</span>
                        <input type="text" placeholder="Title" className="info-input" name='title' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Instructions ( ',' separated )</span>
                        <input type="text" placeholder="Instructions" className="info-input" name='instructions' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Attempts Allowed</span>
                        <input type="number" placeholder="No. of times assesment to be taken" className="info-input" name='attempts_allowed' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Course Id</span>
                        <input type="text" placeholder="Course Id" className="info-input" name='course_id' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                    <label className=" mb-3 shadow-md border border-1  rounded">
                        <span className=' p-2'>Section Id</span>
                        <input type="text" placeholder="Section Id" className="info-input" name='section_id' onChange={event => heandalQuizinfochange(event)} />
                    </label>
                </div>
                <label className=" mb-3 shadow-md border border-1  rounded w-100">
                    <span className=' p-2'>Description</span>
                    <textarea rows="1" cols="90" type="text" placeholder="Description" className="info-input" name='description' onChange={event => heandalQuizinfochange(event)} />
                </label>
                <form onSubmit={onSubmit}>
                    {inputFilds?.map((form, index) =>
                        <div className='mt-3 mb-3' key={index}>
                            <div className='flex justify-between'>
                                <h3>Qus No : {index + 1}</h3>
                                <div className='flex justify-end my-2'>
                                    <Button type="primary" onClick={() => AddOptions(index)}>Add Option <BsPlusSquareDotted className='inline text-[18px] mb-1 ms-2' /></Button>
                                    <Button type="danger" className="ms-2" onClick={() => RemoveQusFiled(index)}>Remove Question  <AiOutlineMinusSquare className='inline text-[18px] mb-1 ms-2' /></Button>
                                </div>
                            </div>
                            <div className='flex'>
                                <select className="select rounded-none select-bordered w-50 p-2 mr-1 mb-3 shadow-md"
                                    name='question_type' onChange={event => heandalFromchange(event, index)} value={form.question_type}>
                                    <option selected>Qus type ?</option>
                                    <option >text</option>
                                    <option >code</option>
                                </select>
                                <select className="select rounded-none select-bordered ms-1 p-2 w-50 mb-3 shadow-md"
                                    name='answer_type' onChange={event => heandalFromchange(event, index)} value={form.answer_type}>
                                    <option selected>Answer type ?</option>
                                    <option >text</option>
                                    <option >image</option>
                                    <option >code</option>
                                </select>
                            </div>
                            {/* <input type="text" placeholder={`Question`} className="input p-3 rounded-none border border-1 w-100 mb-3 shadow-md" name='body' onChange={event => heandalFromchange(event, index)}
                            /> */}
                            <textarea rows="4" cols="90" type="text" placeholder={`Question`} className="input p-3 rounded-none border border-1 w-100 mb-3 shadow-md" name='body' onChange={event => heandalFromchange(event, index)}
                            />
                            {
                                inputFilds[index]?.answer_type === 'text' &&
                                <>
                                    {
                                        inputFilds[index]?.answers?.map((value, idx) => {
                                            return <div key={idx} className='flex align-center mt-2'>
                                                <select className="select rounded-none select-bordered shadow-md px-1"
                                                    name={'is_correct'} onChange={event => handleFromAnswerChange1(event, idx, index)}  >
                                                    <option selected>false</option>
                                                    <option >true</option>
                                                </select>
                                                <Input type="text" placeholder="option" className="input p-2 option_inp rounded-none border border-1 w-100 mb-3 shadow-md ms-2" name={'answer'} onChange={event => handleFromAnswerchange(event, idx, index)} />

                                                <Button className="mt-1 rmBtn absolute right-14" onClick={() => removeOption(index, idx)} danger>
                                                    <DeleteOutlined />
                                                </Button>
                                            </div>
                                        })
                                    }
                                </>
                            }
                            {
                                inputFilds[index]?.answer_type === 'image' &&
                                <>
                                    {
                                        inputFilds[index]?.answers?.map((value, idx) => {
                                            return <div className='flex align-center mt-2'>
                                                <select className="select rounded-none select-bordered shadow-md px-1"
                                                    name={'is_correct'} onChange={event => handleFromAnswerChange1(event, idx, index)}  >
                                                    <option selected>false</option>
                                                    <option >true</option>
                                                </select>
                                                <Input type="file" placeholder="option" className="input p-2 option_inp rounded-none border border-1 w-100 mb-3 shadow-md ms-2" name={'answer'} onChange={event => handleFromAnswerchange(event, idx, index)} />

                                                <Button className="mt-1 rmBtn absolute right-14" onClick={() => removeOption(index, idx)} danger>
                                                    <DeleteOutlined />
                                                </Button>
                                            </div>
                                        })
                                    }
                                </>
                            }
                            {
                                inputFilds[index]?.answer_type === 'code' &&
                                <>
                                    {
                                        inputFilds[index]?.answers?.map((value, idx) => {
                                            return <div key={idx} className='flex align-center mt-2'>
                                                <select className="select rounded-none select-bordered shadow-md px-1 h-[46px]"
                                                    name={'is_correct'} onChange={event => handleFromAnswerChange1(event, idx, index)}  >
                                                    <option selected>false</option>
                                                    <option >true</option>
                                                </select>
                                                <textarea rows="1" cols="90" type="text" placeholder="option" className="input p-2 option_inp rounded-none border border-1 w-100 mb-3 shadow-md mb-0 ms-2" name={'answer'} onChange={event => handleFromAnswerchange(event, idx, index)} />

                                                <Button className="mt-1 rmBtn absolute right-14" onClick={() => removeOption(index, idx)} danger>
                                                    <DeleteOutlined />
                                                </Button>
                                            </div>
                                        })
                                    }
                                </>
                            }
                        </div>

                    )}


                    <div className='flex justify-end mt-3 pb-3'>
                        <span className="assignment-add-btn mr-3" onClick={() => AddQusFiled()}>Add Question  <BsPlusSquareDotted className='inline text-[18px] mb-1 ms-2' /></span>
                    </div>
                </form>
            </div >
        </div >
    );
};

export default AddAssignment;
