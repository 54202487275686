import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export let getAssess = createAsyncThunk('getAssess/assessment', async (questionBankId, thunckAPI) => {
    let { rejectWithValue } = thunckAPI
    try {
        let { data } = await axios.get(`${baseUrl}standaloneAssessment/generate?id=${questionBankId}`);
        return data.results
    } catch (error) {
        return rejectWithValue(error.message)
    }
})
let AssessApi = createSlice({
    name: "quest",
    initialState: { assessment: null, isLoading: false, error: null },
    extraReducers: {
        // get quest
        [getAssess.fulfilled]: (state, action) => {
            state.assessment = action.payload;
            state.isLoading = false;
        },
        [getAssess.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null
        },
        [getAssess.rejected]: (state, action) => {
            state.error = action.payload
            state.isLoading = false;
        },
    },
})

export default AssessApi.reducer;