import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useGetAssesmentReportsByIdQuery } from "../../redux/UsersReport/AssesmentReports";

const CheckResult = ({ attempts, passScore }) => {
  let result = "FAIL";
  for (let i = 0; i < attempts.length; i++) {
    if (attempts[i].score_details.total_percentage >= passScore) {
      result = "PASS";
      break;
    }
  }
  return (
    <>
      <p
        style={{
          width: "40px",
          borderRadius: "5px",
          backgroundColor: result === "PASS" ? "green" : "red",
          color: "white",
          textAlign: "center",
          height: "min-content",
          fontWeight: "bold",
          fontSize: 13,
        }}
        className="p-1"
      >
        {result}
      </p>
    </>
  );
};

const UserReportData = () => {
  const { UserId } = useParams();
  const [assessments, setAssessments] = useState([]);
  const [email, setEmail] = useState("");
  const { data } = useGetAssesmentReportsByIdQuery({ UserId });
  const navigate = useNavigate()
  // console.log(UserId);
  useEffect(() => {
    if (!data) return;
    setAssessments(data.assessments);
    setEmail(data.email);
  }, [data]);
  return (
    <div>
      <div
        style={{
          margin: "25px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h5>{`Assessment Report (${email})`} </h5>{" "}
        {/* <button
        style={{border:'1px solid black'}}
          className="btn"
          onClick={() => navigate(`/admin/UsersReport`)}
        >
          <i
            style={{ paddingRight: "10px" }}
            className="fa-solid fa-backward"
          ></i>
          Back
        </button> */}
      </div>
      <div
        style={{ overflow: "scroll", height: "87vh" }}
        class="accordion"
        id="accordionExample"
      >
        {assessments.map((e, i) => (
          <div className="mx-auto px-4 mb-4 " key={`assessment${i}`}>
            <div class="accordion-item">
              <h2 class="accordion-header" id={`heading${i}`}>
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${i + 1}`}
                  // aria-expanded="true"
                  aria-controls={`collapse${i + 1}`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 50,
                    paddingBottom: "6px",
                  }}
                >
                  <span>
                    <h6>{e.assessment_id.title}</h6>
                  </span>
                  <span style={{ marginBottom: -8 }}>
                    <p
                      style={{
                        width: "110px",
                        borderRadius: "5px",
                        backgroundColor:
                          e.attempts.length !== 0 ? "green" : "red",
                        color: "white",
                        textAlign: "center",
                        height: "min-content",
                        fontWeight: "bold",
                        fontSize: 13,
                      }}
                      className="p-1"
                    >
                      {e.attempts.length !== 0 ? "Attempted" : "Not Attempted"}
                    </p>
                  </span>

                  <span style={{ marginBottom: -8 }}>
                    <CheckResult
                      attempts={e.attempts}
                      passScore={e?.assessment_id?.pass_score}
                    />
                  </span>
                  {/* */}
                </button>
              </h2>
              <div
                id={`collapse${i + 1}`}
                class="accordion-collapse collapse "
                aria-labelledby={`heading${i}`}
                data-bs-parent="#accordionExample"
                style={{ visibility: "visible" }}
              >
                <div class="accordion-body" style={{ margin: 0 }}>
                  {" "}
                  <Table
                    responsive
                    className="border border-2 text-center h-100"
                    hover
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    <thead>
                      <tr>
                        {/* <th>Email</th> */}
                        {/* <th>Assessment Name</th> */}
                        <th>Taken At</th>
                        <th>Status </th>
                        <th>Score Details</th>
                        <th>Total Score </th>
                        <th>Pass Score </th>
                      </tr>
                    </thead>
                    <tbody>
                      {e.attempts?.map((attempt, index) => (
                        <tr key={attempt._id}>
                          {/* <td style={{ width: "10%" }}>{attempt?.email}</td> */}
                          {/* <td style={{ width: "10%" }}>
                              {attempt?.assessment_template_id?.title}
                            </td> */}
                          <td style={{ width: "15%" }}>
                            {new Date(attempt?.created_at).toLocaleDateString()}
                          </td>
                          <td style={{ width: "5%" }}>
                            <p
                              style={{
                                width: "100%",
                                borderRadius: "5px",
                                backgroundColor:
                                  attempt.score_details.total_percentage >=
                                  e?.assessment_id?.pass_score
                                    ? "green"
                                    : "red",
                                color: "white",
                                textAlign: "center",
                                height: "min-content",
                                fontWeight: "bold",
                              }}
                              className="p-1"
                            >
                              {attempt.score_details.total_percentage >=
                              e?.assessment_id?.pass_score
                                ? "PASS"
                                : "FAIL"}
                            </p>
                          </td>

                          <td style={{ width: "35%" }}>
                            {attempt?.score_details?.section_scores?.map(
                              (secScore, i) => {
                                return (
                                  <Fragment>
                                    {
                                      <p className="mb-0 py-1">
                                        {secScore.section_name} -{" "}
                                        {secScore.correct_questions}/
                                        {secScore.total_questions} (
                                        {secScore.score_percentage}%)
                                      </p>
                                    }
                                  </Fragment>
                                );
                              }
                            )}
                          </td>
                          <td style={{ width: "10%" }}>
                            {
                              <p className="mb-0 py-1">
                                {" "}
                                {attempt.score_details.total_correct}/
                                {attempt.score_details.total_questions} (
                                {attempt.score_details.total_percentage}%)
                              </p>
                            }
                          </td>
                          <td style={{ width: "10%" }}>
                            {e?.assessment_id?.pass_score}%
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            {/* <h3>{e.assessment_id.title} <span style={{fontSize:'14px'}}>{e.attempts.length == 0? 'Not Attempted':'Attempted'}</span></h3> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserReportData;
