import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header.jsx";
import TestTable from "../../TestTable/TestTable.js";
import { useSelector } from "react-redux";
import { useSendAssessmentMutation } from "../../../redux/Assessment/AssessmentApi.js";
import { toast } from "react-toastify";
export default function SendAssessment() {
  let { generatedLinks } = useSelector((state) => state.quizOptions);
  const [sendAssessment, { isLoading }] = useSendAssessmentMutation();
  const navigate = useNavigate();
  console.log(generatedLinks?.links);
  const createAssess = (id) => {
    sendAssessment(generatedLinks)
      .unwrap()
      .then((res) => {
        toast.success("Links sent");
        navigate(`/admin`);
        console.log(res.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Links not sent");
      });
  };
  return (
    <Fragment>
      <Header title={`Send Links`} />
      <div className="mx-auto px-4 mb-2">
        <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box text-center">
            <h3 className="font-bold text-lg text-[#f5365c] ">
              Wait Be Care Full !
            </h3>
            <p className="py-4 font-semibold">
              Do you want to <span className="text-[#f5365c] mx-1">Delete</span>
              all the collections ?
            </p>
            <div className="flex justify-center">
              <label htmlFor="assignment-delete-model" className="modal-btn">
                Clear
              </label>
              <label
                htmlFor="assignment-delete-model"
                className="modal-delete-btn"
              >
                Delete
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <button className="btn btn-primary" onClick={createAssess}>
            {isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              "Send Links"
            )}
          </button>
        </div>
        <TestTable generatedLinks={generatedLinks} />
      </div>
    </Fragment>
  );
}
