import React, { useState } from 'react'
import { Fragment } from 'react';
import { useGetQuestionsQuery } from '../../redux/Assignment/AssignmentApi';
import Header from '../Header';
import TestTable from '../TestTable/TestTable';

export default function AtgAssignment() {
    const [page, setpage] = useState(1)
    const [limit, setlimit] = useState(10)
    const [search, setsearch] = useState("")
    const { data: assesments, isLoading } = useGetQuestionsQuery({ type: `atg`, page, limit, search });
    const lenght = assesments?.total_pages;
    let sear = "";
    const test = (event) => {
        sear = event.target.value
        if (sear === "") {
            setsearch(sear)
        }
    }

    const handleSearch = ()=>{
        setsearch(sear)
        setlimit(10)
    }

    return (
        <Fragment>
            <Header title={`Atg Assessments`} />
            <div className="mx-auto px-4 mb-4" >
                {!isLoading ?
                    <Fragment>
                        <div className="modal modal-bottom sm:modal-middle">
                            <div className="modal-box text-center">
                                <h3 className="fw-bold fs-4 text-danger ">Wait Be Care Full !</h3>
                                <p className="py-4 fw-bold">Do you want to <span className="text-danger mx-1">Delete</span>all the Assignment ?</p>
                                <div className="flex justify-center">
                                    <label htmlFor="assignment-delete-model" className="modal-btn">Clear</label>
                                    <label htmlFor="assignment-delete-model" className="modal-delete-btn">Delete</label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end align-items-center mt-4'>
                            <div className="d-flex me-2">
                                <input class="form-control w-75 me-2 rounded h-min " onChange={test} type="search" placeholder="Search by Name" aria-label="Search" />
                                <button className='btn btn-primary' onClick={() => handleSearch()}>Search</button>
                            </div>
                        </div>
                        <TestTable assesments={assesments} page={page} limit={limit} setlimit={setlimit} lenght={lenght} setpage={setpage} />
                    </Fragment> : <div className='vh-100 d-flex justify-content-center align-items-center'>
                        <i className="fas fa-spinner fa-spin fa-3x"></i>
                    </div>
                }
            </div>
        </Fragment>
    );
}
