import React, { useState } from "react";
import axios from 'axios';

import { Link, useNavigate } from "react-router-dom";
let details = [
    { name: 'Assessments', path: '/admin' },
    { name: 'Assessments Report', path: '/admin/UsersReport' },
    { name: 'Assessment Questionbanks', path: '/admin/AssessmentQuestionbanks' },
    { name: 'Attempts', path: '/admin/Attempts' },
    // { name: 'Users', path: '/admin/Users' },
]
const SideNav = () => {
    const [open, setOpen] = useState(false)
    const [openT, setOpent] = useState(true)
    const [activeLi, setActiveLi] = useState(null)
    const handleDropDawn = () => {
        console.log(open)
        setOpen(!open)
    }
    const handleDropDawn2 = () => {
        setOpent(!openT)
    }
    const handleActive = (event, i) => {
        setActiveLi(i)
    }
    let navigate = useNavigate();
    async function sendLoginData() {
        let { data } = await axios.get('https://assessmntbackend.atg.world/users/logout');
        console.log(data.message);
        if (data.message === "User Logged out successfully") {
            navigate("/login")
            localStorage.setItem('message', data.message);
        } else {
            console.log(data.message)
        };
    }
    return (
        <div className="sideNavbar">
            <div className="sideLogo mt-lg-3 mb-lg-5 d-flex flex-column align-items-center justify-content-center">
                <img
                    className="w-[24px] mb-3"
                    src="https://i.ibb.co/bF4ZBJL/YNOS326597.png"
                    alt="logo of atg"
                />
                <h3>Admin Panel</h3>
            </div>
            <div className="">
                <div
                    className="d-flex align-items-center justify-content-between"
                >
                    <div>
                        <i className="mx-3 fa-solid fa-layer-group"></i>
                        <span className="fw-bold fs-6">Standalone </span>
                    </div>
                    <span onClick={handleDropDawn2}><i class={`fa-solid ${!openT ? "fa-caret-down" : "fa-caret-up"} fs-5 mous ms-1 mx-lg-2`}  ></i></span>
                </div>
                {openT && <ul className=" ">
                    {details.map((e, i) => {
                        return <li onClick={() => handleActive(e, i)} className="nav-link ">
                            <Link className="dropdown-item  " to={e.path}>
                                {" "}
                                <p className={activeLi === i ? 'sideNavbar-active link-head p-2' : 'link-head p-2'}>
                                    {" "}
                                    <i className="fa-solid fa-file-lines me-2"></i>
                                    <span>{e.name}</span>
                                </p>
                            </Link>
                        </li>
                    })}
                </ul>}
            </div>
            <div >
                <div
                    className="d-flex align-items-center justify-content-between"
                >
                    <div>
                        <i className="mx-3 fa-solid fa-layer-group"></i>
                        <span className="fw-bold fs-6">Atg </span>
                    </div>
                    <span onClick={handleDropDawn}><i class={`fa-solid ${!open ? "fa-caret-down" : "fa-caret-up"} fs-5 mous ms-1 mx-lg-2`}  ></i></span>
                </div>
                {open && <ul className=" ">
                    <li onClick={() => handleActive(null, 4)} className=" nav-link me-2">
                        <Link to={"/admin/Atg/Assessment"}>
                            {" "}
                            <p className={activeLi === 4 ? 'sideNavbar-active link-head p-2' : 'link-head p-2'}>
                                {" "}
                                <i className="fa-solid fa-file-lines me-2"></i>
                                <span>Assessments</span>
                            </p>
                        </Link>
                    </li>
                </ul>}
            </div>
            <div className='mt-4 w-100'>
                {/* <div className='ass-header mous '>
                    <h4><BiHome className='ass-title ' /> {title}</h4>
                </div> */}
                <button className='btn btn-sm btn-secondary ml-2' onClick={sendLoginData}>Log-Out</button>
            </div>
        </div >
    );
};

export default SideNav;