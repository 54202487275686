import React from 'react'
import { Navigate } from 'react-router-dom';

export default function withGard(Component) {
    const Wrapper = (props) => {

        return sessionStorage.getItem('token') ? <Component /> : <Navigate to="/login" />
    }
    return Wrapper
}
