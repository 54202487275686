import React, { Fragment } from 'react';
import './Admin.css';
import { Outlet } from 'react-router-dom';
import SideNav from '../SideNavbar/SideNav';
import NavBarTest from "../NavBarTest.jsx"
import withGard from '../GardComp/withGard';
const Admin = () => {
    return (
        <Fragment>
            <div className='row pe-0 ps-0 me-0 ms-0'>
                <div className='d-lg-none d-block py-2 px-5'>
                    <NavBarTest />
                </div>
                <div className='col-xl-2 col-lg-3 d-lg-block d-none pe-0 ps-0 '>
                    <div className='position-sticky top-0 left-0 main-sideNav '>
                        <SideNav />
                    </div>
                </div>
                <div className='col-xl-10 col-lg-9 pe-0 ps-0 rightHeight'>
                    <Outlet />
                </div>
            </div>

        </Fragment>
    );
};

export default withGard(Admin);