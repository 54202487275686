import { Table } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
// import { Form, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UsersDataTable from "./UsersDataTable";
import axios from "axios";
import {
  useGetAssesmentReportsQuery,
  useGetAssesmentReportsByEmailQuery,
} from "../../redux/UsersReport/AssesmentReports";

const UsersReport = () => {
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [length, setlength] = useState(13);
  const [searchEmail, setSearchEmail] = useState("");
  const { data, isLoading } = useGetAssesmentReportsQuery({ page, limit });
  console.log(data);

  const { data: emailData } = useGetAssesmentReportsByEmailQuery({
    email: searchEmail,
  });
  const handleSearch = () => {
    console.log(emailData);
    setUsersData([emailData]);
    setpage(1);
    setlength(1);
  };

  useEffect(() => {
    if (searchEmail == "") {
      if (!data) return;
      setUsersData(data.results);
      setpage(data.page);
      setlimit(data.limit);
      setlength(data.total_pages);
      console.log(data);
    }
  }, [searchEmail]);

  useEffect(() => {
    if (!data) return;
    setUsersData(data.results);
    setpage(data.page);
    setlimit(data.limit);
    setlength(data.total_pages);
    console.log(data);
  }, [data]);
  return (
    <Fragment>
      <div className="mx-auto px-4 mb-4 ">
        <div className="d-flex justify-content-end align-items-center mt-4">
          <div className="d-flex me-2">
            <input
              class="form-control w-75 me-2 rounded h-min "
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              type="search"
              placeholder="Search by Email"
              aria-label="Search"
            />
            <button className="btn btn-primary" onClick={() => handleSearch()}>
              Search
            </button>
          </div>
        </div>
        <UsersDataTable
          usersData={usersData}
          page={page}
          limit={limit}
          setlimit={setlimit}
          setpage={setpage}
          lenght={length}
          setlength={setlength}
        />
      </div>
    </Fragment>
  );
};

export default UsersReport;
