import React, { Fragment, useEffect } from "react";
import { AiOutlineFileText, AiOutlineUsergroupDelete } from "react-icons/ai";
import { MdTimer } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGenerateAssessmentTemplateQuery } from "../redux/Assessment/AssessmentApi";
import { getAssess } from "../redux/Assessment/GenerateAssessApi";
import { getQues } from "../redux/Assignment/getQuesApi";
import { userAttempts } from "../redux/Attempts/resultAttemptsApi";
export default function AssessmentQuiz() {
    let navigate = useNavigate();
    const { questionBankId, userId } = useParams();
    // const { data: assessment } = useGenerateAssessmentTemplateQuery(questionBankId)
    const dispatch = useDispatch();
    let { quest } = useSelector((state) => state.quest);
    // let { assessment } = useSelector((state) => state.assessment);
    const { tempLength, attempts } = useSelector((state) => state.resultAttempts)
    useEffect(() => {
        dispatch(getQues({ type: `assessmentTemplate`, id: questionBankId }));
        dispatch(userAttempts({ type: `standaloneAttempt`, idtype: `assessment_template_id`, questionBankId, userId }))
    }, []);
    let assessTemplate = quest?.questions_from_sections?.reduce((acc, ques) => {
        acc += ques.number_of_questions
        return acc
    }, 0)
    const startAssess = () => {
        navigate(quest.assessment_type !== "atg" ? `/start/${questionBankId}/${userId}` : `/${questionBankId}/${quest?.course_id}/${userId}`)
        dispatch(getAssess(questionBankId))
    };
    console.log(quest)
    return (
        <div className="container p-5">
            <div className="assess-backround mb-3">
                <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 39C16.65 39 18 40.35 18 42C18 45.3 15.3 48 12 48C11.49 48 11.01 47.94 10.5 47.85C11.43 46.2 12 44.22 12 42C12 40.35 13.35 39 15 39ZM50.01 0C49.23 0 48.48 0.3 47.88 0.87L21 27.75L29.25 36L56.13 9.12C57.3 7.95 57.3 6.06 56.13 4.89L52.11 0.87C51.51 0.27 50.76 0 50.01 0ZM15 33C10.02 33 6 37.02 6 42C6 45.93 2.52 48 0 48C2.76 51.66 7.47 54 12 54C18.63 54 24 48.63 24 42C24 37.02 19.98 33 15 33Z" fill="white" />
                </svg>

            </div>
            <p className="text-[#6D747A] w-50 my-2 text-[13px] font-medium">
                SKILL TEST
            </p>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h3 className="h3">{quest?.title}</h3>
                    <p className="text-[#6D747A]">
                        {quest?.description}
                    </p>
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-primary py-2 px-5 text-[20px]"
                        onClick={startAssess}
                    >
                        Start Assessment
                    </button>
                    {/*{quest.attempts_allowed <= tempLength && <p className="alert alert-danger my-3">Attempts Exceeded !! Assessment already taken</p>}*/}
                </div>
            </div>
            <div className="my-3">
                <p className="text-[#4E4E4E]">
                    <AiOutlineFileText className="d-inline-block me-2" /> {assessTemplate} questions
                    total
                </p>
                {quest?.graded !== false && <p className="text-[#4E4E4E]">
                    <MdTimer className="d-inline-block me-2" /> {quest?.time_limit} minutes to complete the
                    test
                </p>}
                <p className="text-[#4E4E4E]">
                    <AiOutlineUsergroupDelete className="d-inline-block me-2" /> You need{" "}
                    {quest?.pass_score}% score to pass
                </p>
            </div>
            <p className="text-[#4E4E4E] my-3">Instructions</p>
            <ul className="text-[#4E4E4E]">
                {quest?.instructions && quest?.instructions[0]?.split(",")?.map((instruction, i) => {
                    return <Fragment key={i}>
                        <li>
                            {instruction}
                        </li>
                    </Fragment>
                })}
            </ul>
        </div>
    );
}


// const details = {}
// if (courseId === undefined) {
//   details.assessment_template_id = quest.assessment_template_id
//   details.assessment_id = questionBankId
//   details.user_id = userId
//   questions =

// }
// const atgDetails = {
//   question_bank_id: quest._id,
//   user_id: userId,
//   score_details: res.score_details,
//   assessment_type: !quest.courseId ? "standalone" : "atg"
// }
// const standaloneDetails = {

// }
// addAttempt({ type: quest.assessment_type === "atg" ? "attempt" : "standaloneAttempt", details }).unwrap()
//   .then()
//   .catch((error) => console.log(error));