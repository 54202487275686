import React, { Fragment, useEffect, useState } from 'react'
import { Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCountDawn } from '../redux/features/quizSlice';
import { getQues } from '../redux/Assignment/getQuesApi';
import { useParams } from 'react-router-dom';

const StartingCountdown = () => {
  const { questionBankId, courseId } = useParams();
  const dispatch = useDispatch()
  const { countDawn } = useSelector((state) => state.quizOptions)
  const [showQuiz, setShowQuiz] = useState(false)
  useEffect(() => {
    if (courseId) {
      dispatch(getQues({ type: `questionbank/assessment`, id: questionBankId, }));
    }
  }, [])
  useEffect(() => {
    countDawn > 0 ? setTimeout(() => dispatch(setCountDawn(countDawn - 1)), 1000) : setShowQuiz(true)
  }, [countDawn, dispatch])
  return (
    <Fragment>
      {!showQuiz ? <div className="container-fluid">
        <div className="row">
          <div className="col sc_main_col mmc">

            <div className="sc_message mmc">
              <h4 className="sc_message_text">
                Starting in
              </h4>
            </div>
            <Progress
              type="circle"
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#108ee9',
              }}
              percent={countDawn * 20}
              width={90}
              format={percent => `${percent / 20}`}
            />

          </div>
        </div>
      </div> : ''
      }
    </Fragment>
  )
}

export default StartingCountdown


