import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const AssignmentReportApi = createApi({
    tagTypes: ['AssignmentReport'],
    reducerPath: 'AssignmentReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
    }),

    endpoints: (builder) => ({
        getAssesmentReports: builder.query({
            query: ({ page, limit }) => `/users/role_user/?page=${page}&limit=${limit}`, providesTags: ['AssignmentReport']
        }),

        getAssesmentReportsById: builder.query({ query: ({ UserId }) => `/users/user?id=${UserId}`, providesTags: ['AssignmentReport'] }),

        getAssesmentReportsByEmail: builder.query({ query: ({ email }) => `/users/user?email=${email}`, providesTags: ['AssignmentReport'] })
    })
})

export const { useGetAssesmentReportsQuery, useGetAssesmentReportsByIdQuery, useGetAssesmentReportsByEmailQuery } = AssignmentReportApi;
