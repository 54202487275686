import { Table } from "react-bootstrap";
import React, { Fragment, useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDeleteAssessmentMutation } from "../../redux/Assignment/AssignmentApi";
import { toast } from "react-toastify";
import { useDeleteCollectionMutation } from "../../redux/Collections/CollectionsApi";
import Swal from "sweetalert2";
import { useDeleteAssessmentTemplateMutation } from "../../redux/Assessment/AssessmentApi";

const TestTable = ( {
  assesments,
  collections,
  attempts,
  generatedLinks,
  page,
  setpage,
  setlimit,
  lenght,
  limit,
} ) => {
  console.log( "assessments", assesments, attempts, collections );
  const [deleteAssessment] = useDeleteAssessmentMutation();
  let [deleteCollection] = useDeleteCollectionMutation();
  let [deleteAssessmentTemplate] = useDeleteAssessmentTemplateMutation();
  let navigate = useNavigate();
  const [qadded, setQadded] = useState( "7" );

  const handleLimit = ( e ) => {
    e.preventDefault();
    setpage( 1 );
    setlimit( Number( e.target.value ) );
  };

  const handleDelete = ( _id, title ) => {
    Swal.fire( {
      title: `Are you sure ? `,
      toast: true,
      text: `You want to delete ${title} Assessment!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {
        deleteAssessment( _id )
          .unwrap()
          .then( ( res ) => {
            toast.success( "Deleted Successfully" );
          } )
          .catch( ( error ) => {
            console.log( error );
            toast.error( "Assessment Not Deleted" );
          } );
      }
    } );
  };
  const handleAssessmentTemplateDelete = ( _id, title ) => {
    Swal.fire( {
      title: `Are you sure ? `,
      toast: true,
      text: `You want to delete ${title} Assessment!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {
        deleteAssessmentTemplate( _id )
          .unwrap()
          .then( ( res ) => {
            toast.success( "Deleted Successfully" );
          } )
          .catch( ( error ) => {
            console.log( error );
            toast.error( "Assessment Not Deleted" );
          } );
      }
    } );
  };
  const handleCollectionDelete = ( _id, collection_name ) => {
    Swal.fire( {
      toast: true,
      title: `Are you sure ? `,
      text: `You want to delete ${collection_name} Assessment questionbank!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {
        deleteCollection( _id )
          .unwrap()
          .then( ( res ) => {
            toast.success( "Deleted successfully" );
            navigate( "/admin/AssessmentQuestionbanks" );
          } )
          .catch( ( error ) => {
            console.log( error );
            toast.info( "Error Deleting Assessment Questionbank" );
          } );
      }
    } );
  };
  // let pagin = () => {
  //   let box = [];
  //   for (let i = 1; i <= lenght; i++) {
  //     box.push(
  //       <Pagination.Item onClick={() => setpage(i)}>{i}</Pagination.Item>
  //     );
  //   }
  //   return box;
  // };

  const pagin = () => {
    const visiblePages = 5; // Number of visible page numbers
    const pages = [];

    // Calculate the range of page numbers to show
    let startPage = Math.max( 1, page - Math.floor( visiblePages / 2 ) );
    let endPage = Math.min( lenght, startPage + visiblePages - 1 );

    // Adjust the range if there are not enough pages
    if ( endPage - startPage + 1 < visiblePages ) {
      startPage = Math.max( 1, endPage - visiblePages + 1 );
    }

    for ( let i = startPage; i <= endPage; i++ ) {
      pages.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setpage( i )}>
          {i}
        </Pagination.Item>
      );
    }

    return pages;
  };

  console.log( lenght );
  let countAssessTemplate = ( i ) => {
    let assessTemplate = assesments?.results[i]?.questions_from_sections.reduce(
      ( acc, ques ) => {
        acc += ques.number_of_questions;
        return acc;
      },
      0
    );
    return assessTemplate;
  };

  let countQuestionsSections = ( i ) => {
    //console.log( "c", collections[i] );
    let questions = collections[i]?.sections.reduce( ( acc, section ) => {
      acc += section.questions.length;
      return acc;
    }, 0 );
    return questions;
  };

  // console.log(countAssessTemplate(1));
  return (
    <Fragment>
      <Table responsive className="border border-2 text-center h-100" hover>
        <thead>
          <tr>
            {generatedLinks && <th>Email</th>}
            {generatedLinks && <th>Link</th>}
            {attempts && <th>Email</th>}
            {attempts && <th>Assessment Name</th>}
            {attempts && <th>Taken At</th>}
            {attempts && <th>Status </th>}
            {attempts && <th>Score Details</th>}
            {attempts && <th>Total Score </th>}
            {attempts && <th>Pass Score </th>}
            {assesments && <th>Title</th>}
            {collections && <th> Name</th>}
            {collections && <th>Total Sections</th>}
            {collections && <th>Total Questions</th>}
            {collections && (
              <th>
                Questions Added in -
                <select
                  className="btn btn-sm btn-primary mt-2"
                  onChange={( e ) => {
                    console.log( "data", e.target.value );
                    setQadded( e.target.value );
                  }}
                >
                  <option
                    key="1"
                    className="bg-white text-align-left text-black p-3"
                    value="7"
                  >
                    Last 7 Days
                  </option>
                  <option
                    key="2"
                    className="bg-white align-left text-black p-3"
                    value="14"
                  >
                    Last 14 Days
                  </option>
                  <option
                    key="3"
                    className="bg-white align-left text-black p-3"
                    value="30"
                  >
                    Last 30 Days
                  </option>
                </select>
              </th>
            )}
            {assesments && (
              <Fragment>
                {assesments?.results[0]?.assessment_type === "atg" && (
                  <th>Course Name</th>
                )}
                {assesments?.results[0]?.assessment_type === "atg" && (
                  <th>Course Section Name</th>
                )}
                {assesments?.results[0]?.assessment_type !== "atg" && (
                  <th>From Collection</th>
                )}
                {assesments?.results[0]?.assessment_type !== "atg" && (
                  <th>Type</th>
                )}
                {assesments?.results[0]?.assessment_type !== "atg" && (
                  <th>Attempts Allowed</th>
                )}
                {assesments?.results[0]?.assessment_type !== "atg" && (
                  <th>Time Limit</th>
                )}
                {assesments?.results[0]?.assessment_type !== "atg" && (
                  <th>Questions From Sections</th>
                )}
                {assesments?.results[0]?.assessment_type !== "atg" && (
                  <th>Pass Score</th>
                )}
              </Fragment>
            )}
            {!attempts && !generatedLinks && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {assesments && (
            <Fragment>
              {assesments?.results.map( ( assesment, index ) => (
                <tr className="" key={assesment._id}>
                  <td style={{ width: "15%" }}>{assesment.title}</td>
                  {assesment.assessment_type === "atg" && (
                    <td style={{ width: "10%" }}>{assesment.course_name}</td>
                  )}
                  {assesment.assessment_type !== "atg" && (
                    <td style={{ width: "10%" }}>
                      {assesment?.collection_id?.collection_name}
                    </td>
                  )}
                  {assesment.assessment_type === "atg" && (
                    <td style={{ width: "20%" }}>
                      {assesment.course_section_name}
                    </td>
                  )}
                  {assesment.assessment_type !== "atg" && (
                    <td style={{ width: "10%" }}>
                      {assesment.graded === true ? "Graded" : "Non-Graded"}
                    </td>
                  )}
                  {assesment.assessment_type !== "atg" && (
                    <td style={{ width: "10%" }}>
                      {assesment.attempts_allowed}
                    </td>
                  )}
                  {assesment.assessment_type !== "atg" && (
                    <td style={{ width: "10%" }}>{assesment.time_limit}</td>
                  )}
                  {/*{assesment.assessment_type !== "atg" && (
                    <td style={{ width: "10%" }}>
                      {countAssessTemplate(index)}
                    </td>
                  )}*/}
                  {assesment.assessment_type !== "atg" && (
                    <td style={{ width: "20%" }}>

                      {assesment.questions_from_sections.map( ( ele ) => {
                        return <p>{ele.section_name} - {ele.number_of_questions}</p>
                      } )}

                    </td>
                  )}
                  {assesment.assessment_type !== "atg" && (
                    <td style={{ width: "10%" }}>{assesment.pass_score}</td>
                  )}
                  <td style={{ width: "15%" }}>
                    <div className="d-flex d-md-block justify-content-between">
                      {assesment.assessment_type !== "atg" ? (
                        <button
                          className="btn"
                          onClick={() =>
                            navigate(
                              `/admin/Assessment/GenerateAssessment/${assesment._id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-paper-plane"></i>
                        </button>
                      ) : (
                        ""
                      )}
                      {assesment.assessment_type !== "atg" ? (
                        <button
                          className="btn"
                          onClick={() =>
                            navigate(
                              `/admin/EditAssessment/${assesment.collection_id._id}/${assesment._id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-pen"></i>
                        </button>
                      ) : (
                        ""
                      )}
                      {assesment.assessment_type === "atg" ? (
                        <button
                          className="btn me-3"
                          onClick={() =>
                            navigate(
                              `/admin/update_Assignment/${assesment._id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-pen me-2"></i>
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn"
                        onClick={() =>
                          assesment.assessment_type !== "atg"
                            ? handleAssessmentTemplateDelete(
                              assesment._id,
                              assesment.title
                            )
                            : handleDelete( assesment._id, assesment.title )
                        }
                      >
                        <i class="fa-solid fa-trash me-2"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ) )}
            </Fragment>
          )}
          {collections && (
            <Fragment>
              {collections?.map( ( collection, index ) => (
                <tr key={collection._id}>
                  <td style={{ width: "20%" }}>{collection.collection_name}</td>
                  <td style={{ width: "15%" }}>
                    {collection.sections.length.toString()}
                  </td>
                  <td style={{ width: "15%" }}>
                    {countQuestionsSections( index )}
                  </td>
                  <td style={{ width: "15%" }}>
                    {qadded == "14"
                      ? collection.last_fourteen
                      : qadded == "30"
                        ? collection.last_thirty
                        : collection.last_seven}
                  </td>
                  <td style={{ width: "35%" }}>
                    <div className="d-flex flex-md-row flex-column justify-content-md-evenly">
                      <button
                        className="btn"
                        onClick={() =>
                          navigate( `/admin/CreateAssessment/${collection._id}` )
                        }
                      >
                        <i class="fa-solid fa-plus me-2"></i>Create Assessment
                      </button>
                      <div>
                        <button
                          className="btn"
                          onClick={() =>
                            navigate(
                              `/admin/AssessmentQuestionbanks/UpdateAssessmentQuestionbank/${collection._id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-pen me-2"></i>
                        </button>
                        <button
                          className="btn "
                          onClick={() =>
                            handleCollectionDelete(
                              collection._id,
                              collection.collection_name
                            )
                          }
                        >
                          <i class="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ) )}
            </Fragment>
          )}
          {generatedLinks && (
            <Fragment>
              {generatedLinks?.links?.map( ( link ) => (
                <tr key={link.userId}>
                  <td style={{ width: "40%" }}>{link.email}</td>
                  <td style={{ width: "60%" }}>{link.link}</td>
                </tr>
              ) )}
            </Fragment>
          )}
          {attempts && (
            <Fragment>
              {attempts?.map( ( attempt, index ) => (
                <tr key={attempt._id}>
                  <td style={{ width: "10%" }}>{attempt?.email}</td>
                  <td style={{ width: "10%" }}>
                    {attempt?.assessment_template_id?.title}
                  </td>
                  <td style={{ width: "15%" }}>{attempt?.created_at}</td>
                  <td>
                    <p
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        backgroundColor:
                          attempt?.status === "pass" ? "green" : "red",
                        color: "white",
                        textAlign: "center",
                        height: "min-content",
                        fontWeight: "bold",
                      }}
                      className="p-1"
                    >
                      {attempt?.status.toUpperCase()}
                    </p>
                  </td>

                  <td style={{ width: "35%" }}>
                    {attempt?.score_details?.section_scores?.map(
                      ( secScore, i ) => {
                        return (
                          <Fragment>
                            {
                              <p className="mb-0 py-1">
                                {secScore.section_name} -{" "}
                                {secScore.correct_questions}/
                                {secScore.total_questions} (
                                {secScore.score_percentage}%)
                              </p>
                            }
                          </Fragment>
                        );
                      }
                    )}
                  </td>
                  <td style={{ width: "10%" }}>
                    {
                      <p className="mb-0 py-1">
                        {" "}
                        {attempt.score_details.total_correct}/
                        {attempt.score_details.total_questions} (
                        {attempt.score_details.total_percentage}%)
                      </p>
                    }
                  </td>
                  <td style={{ width: "10%" }}>{attempt?.pass_score}%</td>
                </tr>
              ) )}
            </Fragment>
          )}
        </tbody>
      </Table>
      {!generatedLinks && (
        <div className="row">
          <div className="col-md-11 col-xs-12 justify-content-start">
            <Pagination size="xs">
              <Pagination.First onClick={() => setpage( 1 )} />
              <Pagination.Prev
                onClick={() => setpage( Math.max( 1, page - 1 ) )}
                disabled={page <= 1}
              />
              {pagin()}
              <Pagination.Next
                onClick={() => setpage( Math.min( lenght, page + 1 ) )}
                disabled={page >= lenght}
              />
              <Pagination.Last onClick={() => setpage( lenght )} />
            </Pagination>
          </div>
          <div className="col-md-1 col-xs-12 justify-content-end">
            <Form.Select
              aria-label="Default select example"
              onChange={( e ) => handleLimit( e )}
              className="select-box"
              value={limit}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </Form.Select>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TestTable;
