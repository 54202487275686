import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export let getAttempts = createAsyncThunk('getTempts/attempts', async ({ page, limit, search }, thunckAPI) => {
    let { rejectWithValue } = thunckAPI
    try {
        let { data } = await axios.get(`${baseUrl}standaloneAttempt?page=${page}&limit=${limit}&email=${search}`);
        return data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})
export let userAttempts = createAsyncThunk('filterTempts/attempts', async ({ userId, questionBankId }, thunckAPI) => {
    let { rejectWithValue } = thunckAPI
    try {
        let { data } = await axios.get(`${baseUrl}attempt/?user_id=${userId}&question_bank_id=${questionBankId}`);
        const atLength = data?.attempts?.length
        return atLength
    } catch (error) {
        return rejectWithValue(error.message)
    }
})
let temptsApi = createSlice({
    name: "attempts",
    initialState: { attempts: [], tempLength: 0, isLoading: false, error: null },
    extraReducers: {
        // get tempts
        [getAttempts.fulfilled]: (state, action) => {
            let att = action.payload.attempts.map((ele) => {
                const options = { year: "numeric", month: "long", day: "numeric" }
                let date = new Date(ele.created_at).toLocaleDateString(undefined, options)
                ele.created_at = date
                return ele
            })
            state.attempts = att;
            state.tempLength = action.payload.total_pages;
            state.isLoading = false;
        },
        [getAttempts.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null
        },
        [getAttempts.rejected]: (state, action) => {
            state.error = action.payload
            state.isLoading = false;
        },
    }
})

export default temptsApi.reducer;