
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from 'react-router-dom';
import style from "./Assessment/CreateAssessment/Accordion/Accordion.module.css";
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
let details = [
  { name: 'Assessments', path: '/admin' },
  { name: 'Assessments Report', path: '/admin/UsersReport' },
  { name: 'Assessment Questionbanks', path: '/admin/AssessmentQuestionbanks' },
  { name: 'Attempts', path: '/admin/Attempts' },
]
function BasicExample() {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false)
  const [openT, setOpent] = useState(true)
  const [activeLi, setActiveLi] = useState(null)
  let navigate = useNavigate();
  async function sendLoginData() {
    let { data } = await axios.get('https://assessmntbackend.atg.world/users/logout');
    console.log(data.message);
    if (data.message === "User Logged out successfully") {
      navigate("/login")
      localStorage.setItem('message', data.message);
    } else {
      console.log(data.message)
    };
  }
  const handleDropDawn = () => {
    console.log(open)
    setOpen(!open)
  }
  const handleDropDawn2 = () => {
    setOpent(!openT)
  }
  const handleActive = (event, i) => {
    setActiveLi(i)
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Navbar expand="lg" >
      <Navbar.Brand>
        <div className="sideLogo mt-lg-4 mb-lg-5  d-flex align-items-center justify-content-center">
          <img
            className="w-[24px] mr-4"
            src="https://i.ibb.co/bF4ZBJL/YNOS326597.png"
            alt="logo of atg"
          />
          <h3>Admin Panel</h3>
        </div>
      </Navbar.Brand>
      <Button variant="none" onClick={handleShow}>
        <i class="fa-solid fa-bars fs-3"></i>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>   <div className="sideLogo mt-lg-4 mb-lg-5  d-flex align-items-center justify-content-center">
            <img
              className="w-[24px] mr-4"
              src="https://i.ibb.co/bF4ZBJL/YNOS326597.png"
              alt="logo of atg"
            />
            <h3>Admin Panel</h3>
          </div></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative">
          <div className="">
            <div
              className="d-flex align-items-center justify-content-between"
            >
              <div className='mb-3'>
                <i className="mx-3 fa-solid fa-layer-group"></i>
                <span className="fw-bold fs-6">Standalone </span>
              </div>
              <span onClick={handleDropDawn2}><i class={`fa-solid ${!openT ? "fa-caret-down" : "fa-caret-up"} fs-5 mous ms-1 mx-lg-2`}  ></i></span>
            </div>
            {openT && <ul className=" ">
              {details.map((e, i) => {
                return <li onClick={() => handleActive(e, i)} className="nav-link ">
                  <Link className="dropdown-item  " to={e.path}>
                    {" "}
                    <p className={activeLi === i ? 'sideNavbar-active link-head p-2' : 'link-head p-2'}>
                      {" "}
                      <i className="fa-solid fa-file-lines me-2"></i>
                      <span>{e.name}</span>
                    </p>
                  </Link>
                </li>
              })}
            </ul>}
          </div>
          <div >
            <div
              className="d-flex align-items-center justify-content-between"
            >
              <div className='mb-3'>
                <i className="mx-3 fa-solid fa-layer-group"></i>
                <span className="fw-bold fs-6">Atg </span>
              </div>
              <span onClick={handleDropDawn}><i class={`fa-solid ${!open ? "fa-caret-down" : "fa-caret-up"} fs-5 mous ms-1 mx-lg-2`}  ></i></span>
            </div>
            {open && <ul className=" ">
              <li onClick={(e) => handleActive(e, 4)} className=" nav-link me-2">
                <Link to={"/admin/Atg/Assessment"} className="dropdown-item  " >
                  {" "}
                  <p className={activeLi === 4 ? 'sideNavbar-active link-head p-2' : 'link-head p-2'}>
                    {" "}
                    <i className="fa-solid fa-file-lines me-2 "></i>
                    <span className='text-black'>Assessments</span>
                  </p>
                </Link>
              </li>
            </ul>}
          </div>
          <button className='btn btn-sm btn-secondary  position-absolute bottom-[10px] right-[10px]' onClick={sendLoginData}>Log-Out</button>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default BasicExample;