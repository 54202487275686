import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
export default function Login() {
    let navigate = useNavigate();
    let [errorList, setErrorList] = useState([]);
    let [isloading, setIsLoading] = useState(false);
    let [user, setUser] = useState({
        email: "",
        password: "",
    });

    function getUserData(e) {
        let newUser = { ...user };
        newUser[e.target.name] = e.target.value;
        setUser(newUser);
    }

    async function sendLoginData() {
        let { data } = await axios.post('https://assessmntbackend.atg.world/users/login', user);
        // console.log(data.message);
        if (data.message === "Authentication successful") {
            sessionStorage.setItem('token', data.token);
            navigate("/admin")
            setIsLoading(false);
        } else {
            console.log(data.message)
            setIsLoading(false);
        };
    }
    function submitSendData(e) {
        e.preventDefault();
        setIsLoading(true);
        console.log(user);
        sendLoginData();
    }
    return (
        <Fragment>
            <div className='container-md py-5'>
                <div className="mx-auto px-4 mb-2 shadow">
                    <div className=' py-3 px-4 align-items-center d-flex justify-content-center'>
                        <img
                            className="w-[50px] me-3"
                            src="https://i.ibb.co/bF4ZBJL/YNOS326597.png"
                            alt="logo of atg"
                        />
                        <h4 className='mb-0 fw-bold fs-2'>Admin Panel </h4>
                    </div>
                    <form className="mx-auto mb-5  p-4 rounded" onSubmit={submitSendData}>
                        <h3 className=' fw-bold mb-2'>Login</h3>
                        <hr className='mb-4' />
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label fw-bold fs-6 border-bottom rounded py-2 ">
                                Email <span className="text-danger">*</span>
                            </label>
                            <input
                                onChange={getUserData}
                                type="text"
                                className="form-control border-secondary p-2 border border-2 rounded"
                                id="email"
                                name="email"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label fw-bold fs-6 border-bottom rounded py-2">
                                Password <span className="text-danger">*</span>
                            </label>
                            <input
                                onChange={getUserData}
                                type="password"
                                className="form-control border-secondary p-2 border border-2 rounded"
                                id="password"
                                name="password"
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            {isloading === true ? (
                                <i className="fas fa-spinner fa-spin"></i>
                            ) : (
                                "Login"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}
