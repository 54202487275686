import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getQues } from "../../redux/Assignment/getQuesApi";
import {
  useDeleteAssessmentMutation,
  useUpdateAssessmentMutation,
} from "../../redux/Assignment/AssignmentApi";
import UpdateForm from "./UpdateForm";
import Header from "../Header";
const UpdateAssignment = () => {
  const dispatch = useDispatch();
  const { qbId } = useParams();
  let { quest, isLoading } = useSelector((state) => state.quest);
  const [assesments, setAssessments] = useState([]);
  const [problems, setProblems] = useState([]);
  const [gradeCheck, setgradeCheck] = useState(null);
  const [Gradevalid, setGradevalid] = useState(null);
  const [problemsType, setProblemsType] = useState([]);
  const [updateAssessment, { isLoading: isUpdating }] =
    useUpdateAssessmentMutation();
  const [deleteAssessment, { isLoading: isDeleting }] =
    useDeleteAssessmentMutation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  console.log(quest);
  useEffect(() => {
    dispatch(getQues({ type: `questionbank`, id: qbId }));
  }, []);

  useEffect(() => {
    if (quest) {
      const clone = JSON.parse(JSON.stringify(quest));
      setAssessments(clone);
      setQuestions(clone?.questions);
    }
  }, [quest]);

  const returnQuestion = () => {
    return {
      question_type: "",
      answer_type: "",
      body: "",
      correct_answer_description: "",
      answers: [
        {
          answer: "",
          is_correct: false,
        },
      ],
      section_id: assesments.section_id,
      __v: 0,
    };
  };

  const AddOp = (questionId) => {
    if (
      questions[questionId].answer_type &&
      questions[questionId].question_type
    ) {
      const data = [...questions];
      const newOption = {
        answer: "",
        is_correct:
          data[questionId].answers.find((e) => e.is_correct === true) ===
            undefined
            ? true
            : false,
      };
      data[questionId].answers.push(newOption);
      setQuestions(data);
    } else {
      toast.info("Please Select The Option Type");
    }
  };

  const rmve = (questionIndex, answerIndex) => {
    const data = [...questions];

    const items = data[questionIndex].answers.filter((answer, idx) => {
      return idx !== answerIndex;
    });
    data[questionIndex].answers = items;
    setQuestions(data);
  };

  const addQuestion = () => {
    const data = [...questions];
    const newQuestion = returnQuestion();
    data.push(newQuestion);
    setQuestions(data);
  };

  const handleFromchange = (event, index) => {
    let data = [...questions];
    data[index][event.target.name] = event.target.value;
    setQuestions(data);
  };
  const handleCorrectchange = (event, answerIdx, questIndex) => {
    let data = [...questions];
    // console.log(data)
    data[questIndex].answers[answerIdx][event.target.name] = JSON.parse(
      event.target.value
    );
    // data[index].answers[index].answer = event.target.value
    setQuestions(data);
  };
  const handleFromAnswerchange = (event, answerIndex, questionIndex) => {
    let data = [...questions];
    data[questionIndex].answers[answerIndex].answer = event.target.value;
    setQuestions(data);
  };

  const handleFromAnswerchange1 = (event, answerIndex, questionIndex) => {
    if (questions[questionIndex].answer_type === "image") {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const string = reader.result.toString();
        let data = [...questions];
        data[questionIndex].answers[answerIndex].answer = string;
        setQuestions(data);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  const RemoveQusFiled = (index) => {
    const newArray = [...questions];
    newArray.splice(index, 1);
    setQuestions(newArray);
  };
  const checkQuestionsOptions = () => {
    let values = [];
    let data = [];
    let i = 0;
    for (const quest of questions) {
      if (quest.answers.find((e) => e.is_correct === true) === undefined) {
        values.push(false);
        data.push(i);
      }
      if (quest.question_type === "Question type ?") {
        values.push(quest.question_type);
        data.push(i);
      }
      i++;
    }
    setProblems(data);
    console.log(problems);
    setProblemsType(values);
    console.log(problemsType);
    values = values.find((e) => e === "Question type ?" || e === false);
    return values;
  };
  // let url = process.env.REACT_APP_API_BASE_URL
  const handleDelete = (_id) => {
    deleteAssessment({ id: _id })
      .unwrap()
      .then((res) => {
        toast.success("Deleted successfully");
        navigate("/admin/Atg/Assessment");
      })
      .catch((error) => {
        console.log(error);
        toast.info("Error Deleting Assessment");
      });
  };

  const onSave = (_id) => {
    const newQus = [...questions];
    const newAssesments = { ...assesments };
    newAssesments.questions = newQus;
    newAssesments.graded = gradeCheck;
    setAssessments(newAssesments);
    console.log(newAssesments);
    // if (gradeCheck) {
    if (checkQuestionsOptions() === undefined) {
      updateAssessment({ id: _id, assessment: newAssesments })
        .unwrap()
        .then((res) => {
          toast.success("Assessment Updated");
          navigate("/admin/Atg/Assessment");
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Assessment Not Updated");
        });
    } else if (checkQuestionsOptions() === "Question type ?") {
      toast.error("Please Select Question Type For Each Question");
    } else if (checkQuestionsOptions() === false) {
      toast.error("Please Select One Correct Answer for Each Question");
    }
    //   setGradevalid(true);
    // } else {
    //   setGradevalid(false);
    // }
  };
  const AddImageQuest = (mainIndex, index) => {
    const data = [...questions];
    const newImg = {
      img: "",
    };
    data[mainIndex].questions[index].question_images.push(newImg);
    setQuestions(data);
  };
  const handleImageQuestchange = (event, imgQuestIndex, qusIndex) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const string = reader.result.toString();
      let data = [...questions];
      data[qusIndex].question_images[imgQuestIndex].img = string;
      setQuestions(data);
    };
    reader.readAsDataURL(file);
  };
  const removeImgOption = (questionIndex, mainIndex) => {
    const data = [...questions];

    const items = data[questionIndex].question_images.filter((answer, idx) => {
      return idx !== mainIndex;
    });

    data[questionIndex].question_images = items;
    setQuestions(data);
  };
  const HandleImageAnswer = (qusIndex) => {
    const data = [...questions];
    data[qusIndex].question_images = [];
    setQuestions(data);
  };
  return (
    <Fragment>
      <Header
        title={`Update Assessments`}
        secTitle={`Update Assessment`}
        path={`/admin/Atg/Assessment`}
      />
      <div className="assignment-adding-div mx-auto px-4 shadow ">
        {!isLoading ? (
          <Fragment>
            <div>
              <form>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <Form.Label
                      for="flexCheckDefault"
                      className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block"
                    >
                      Assessment Type{" "}
                      <span className="text-danger">
                        {Gradevalid === false ? "(required)" : "*"}
                      </span>
                    </Form.Label>
                    <div className="d-flex mt-2">
                      <div class="form-check me-3">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="validationFormCheck2"
                          checked={assesments?.graded}
                          onClick={(e) => setgradeCheck(true)}
                          name="grade-check"
                        />
                        <label
                          class="form-check-label"
                          for="validationFormCheck2"
                        >
                          Graded
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="validationFormCheck1"
                          checked={!assesments?.graded}
                          onClick={(e) => setgradeCheck(false)}
                          name="grade-check"
                        />
                        <label
                          class="form-check-label"
                          for="validationFormCheck1"
                        >
                          Non-Graded
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Attempts Allowed <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      value={assesments?.attempts_allowed}
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          attempts_allowed: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="No. of times assesment to be taken"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Course Id <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.course_id}
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          course_id: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Course Id"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Course Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.course_name}
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          course_name: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Course Name"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Section Id <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.section_id}
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          section_id: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Section Id"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Course Section Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.course_section_name}
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          course_section_name: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Course Section Name"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Pass Score <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.pass_score}
                      type="text"
                      placeholder="Pass score"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                      name="pass_score"
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          pass_score: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.title}
                      type="text"
                      placeholder="Title"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                      name="title"
                      onChange={(e) =>
                        setAssessments({ ...assesments, title: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Instructions ( ',' separated ){" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.instructions}
                      type="text"
                      placeholder="Instructions"
                      className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                      name="instructions"
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          instructions: e.target.value,
                        })
                      }
                    />
                  </div>
                  {gradeCheck !== false && (
                    <div className="col-lg-4 col-md-6">
                      <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                        Time Limit ( minutes ){" "}
                        <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        value={assesments?.time_limit}
                        onChange={(e) =>
                          setAssessments({
                            ...assesments,
                            time_limit: e.target.value,
                          })
                        }
                        type="text"
                        placeholder="Time in min"
                        className="border-secondary p-2 border border-2 rounded mt-2 mb-3"
                      />
                    </div>
                  )}
                  <div className="col-lg-8 ">
                    <Form.Label className="fw-bold fs-6 border-bottom rounded py-2">
                      Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={assesments?.description}
                      placeholder="Description"
                      className="border p-2 border-secondary border-2 rounded mt-2 w-100 mb-3 desc"
                      name="description"
                      onChange={(e) =>
                        setAssessments({
                          ...assesments,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </form>
              <form action={onSubmit}>
                <>
                  {questions?.map((question, questionIndex) => (
                    <UpdateForm
                      AddImageQuest={AddImageQuest}
                      removeImgOption={removeImgOption}
                      handleImageQuestchange={handleImageQuestchange}
                      HandleImageAnswer={HandleImageAnswer}
                      gradeCheck={gradeCheck}
                      problemsType={problemsType}
                      problems={problems}
                      handleCorrectchange={handleCorrectchange}
                      question={question}
                      questionIndex={questionIndex}
                      handleFromAnswerchange={handleFromAnswerchange}
                      RemoveQusFiled={RemoveQusFiled}
                      handleFromchange={handleFromchange}
                      setQuestions={setQuestions}
                      rmve={rmve}
                      AddOp={AddOp}
                      handleFromAnswerchange1={handleFromAnswerchange1}
                    />
                  ))}
                </>
              </form>
              <div className="flex justify-end mt-4 pb-4">
                <button
                  className="btn btn-outline-secondary rounded-pill me-3  px-3 py-2 shadow-sm"
                  onClick={() => addQuestion()}
                >
                  Add Question{" "}
                  <BsPlusSquareDotted className="inline fs-6 mb-1 ml-2" />
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-end m-4">
              <button
                type="button"
                class="btn btn-primary rounded-pill me-3  px-3 py-2 shadow-sm"
                onClick={() => onSave(qbId)}
              >
                {isUpdating ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  "Save"
                )}
              </button>
              <button
                type="button"
                class="btn btn-danger rounded-pill  px-3 py-2 shadow-sm"
                onClick={() => handleDelete(qbId)}
              >
                {isDeleting ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </Fragment>
        ) : (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <i className="fas fa-spinner fa-spin fa-3x"></i>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default UpdateAssignment;