import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import TestTable from '../TestTable/TestTable.js';
import { useDispatch, useSelector } from "react-redux";
import { getAttempts } from "../../redux/Attempts/AttemptsApi";
import Header from "../Header.jsx";
export default function Attempts() {
    const [page, setpage] = useState(1)
    const [limit, setlimit] = useState(10)
    const [search, setsearch] = useState("")
    let { attempts, tempLength, isLoading } = useSelector(state => state.attempts);
    const dispatch = useDispatch();
    console.log(isLoading);
    useEffect(() => {
        dispatch(getAttempts({ page, limit, search }))
    }, [dispatch, limit, page, search]);
    let sear = "";
    const test = (event) => {
        sear = event.target.value
        if (sear === "") {
            setsearch(sear)
        }
    }

    const handleSearch = ()=>{
        setsearch(sear)
        setlimit(10)
    }

    return (
        <Fragment>
            <Header title={`Attempts`} />
            <div className="mx-auto px-4 mb-4 ">
                {!isLoading ?
                    <Fragment>
                        <div className='d-flex justify-content-end align-items-center mt-4'>
                            <div className="d-flex me-2">
                                <input class="form-control w-75 me-2 rounded h-min " onChange={test} type="search" placeholder="Search by Email" aria-label="Search" />
                                <button className='btn btn-primary' onClick={() => handleSearch()}>Search</button>
                            </div>
                        </div>
                        <TestTable attempts={attempts} limit={limit} page={page} setlimit={setlimit} lenght={tempLength} setpage={setpage} />
                    </Fragment> : <div className='vh-100 d-flex justify-content-center align-items-center'>
                        <i className="fas fa-spinner fa-spin fa-3x"></i>
                    </div>
                }
            </div>
        </Fragment>
    );
}
