

export const advices = [{
    scores: 1,
    advice: 'Outstanding',
    subAdvice: 'You are one of the top performer on this quiz'
},

{
    scores: 2,
    advice: 'Excellent',
    subAdvice: 'This score is better than 80% people we got, you did amazing'
},

{
    scores: 3,
    advice: 'Good',
    subAdvice: 'This score is one of the good ones we got so far, You’ll pass with this score.'
},

{
    scores: 4,
    advice: 'Can Improve',
    subAdvice: 'You are good, but you can do much better we know that, you can try again.'
},
{
    scores: 5,
    advice: 'Retake',
    subAdvice: 'This is better than 50%, but not enough, we know you can do much better, try again.'
},
{
    scores: 6,
    advice: 'Try Again',
    subAdvice: 'Not enough, you can do much better we know that, please try again.'
}
]


















