import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGenerateAssessmentMutation } from "../../../redux/Assessment/AssessmentApi.js";
import Header from "../../Header.jsx";
import { setGeneratedLinks } from "../../../redux/features/quizSlice.js";
import { useDispatch } from "react-redux";
export default function GenerateAssessment() {
    const navigate = useNavigate();
    let { AId } = useParams();
    const dispatch = useDispatch();
    const [email, setEmail] = useState([]);
    const [GenerateAssessment, { isLoading }] = useGenerateAssessmentMutation();
    const handlEmail = (event) => {
        let data = [...email];
        data = event.target.value.split(",");
        data = data.map((ele)=>ele.trim())
        setEmail(data);
    };
    console.log(AId)
    const createAssess = () => {
        const LastAssessment = {
            assessment_template_id: AId,
            emails: email,
        };
        console.log(LastAssessment)
        GenerateAssessment(LastAssessment)
            .unwrap()
            .then((res) => {
                toast.success("Links Generated");
                navigate(`/admin/Assessment/SendAssessment`);
                console.log(res.result)
                dispatch(setGeneratedLinks(res.result))
            })
            .catch((error) => {
                console.log(error);
                toast.error("Links is not Generated");
            });
    };
    return (
        <Fragment>
            <Header title={`Generate Assessment`} secTitle={`Back to Assessments`} path={"/admin"} />
            <div className="assignment-adding-div mx-auto px-4 shadow ">
                <div className="">
                    <h5 className="fs-5  py-4 text-center fw-bold">
                        Generate Links
                    </h5>
                    <div className="w-75 mx-auto my-4">
                        <div className="mb-3">
                            <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label fw-bold fs-6 border-bottom rounded py-2"
                            >
                                Email Addresses <span className="text-danger">*</span>
                            </label>
                            <input
                                onChange={(e) => handlEmail(e)}
                                name="email"
                                type="email"
                                className="form-control rounded border-secondary p-3"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                            />
                        </div>
                        <button
                            className="btn btn-primary mt-3 d-block ms-auto rounded-pill shadow-sm"
                            onClick={() => createAssess()}
                        >
                            {isLoading ? <i className="fas fa-spinner fa-spin"></i> : " Generate Links"}
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
