import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export let getQues = createAsyncThunk('getQues/quest', async ({ type, id }, thunckAPI) => {
    let { rejectWithValue } = thunckAPI
    try {
        let { data } = await axios.get(`${baseUrl}${type}?id=${id}`);
        return data.results[0]
    } catch (error) {
        return rejectWithValue(error.message)
    }
})
let quesApi = createSlice({
    name: "quest",
    initialState: { quest: [], question: [], isLoading: false, error: null },
    extraReducers: {
        // get quest
        [getQues.fulfilled]: (state, action) => {
            state.quest = action.payload;
            state.isLoading = false;
        },
        [getQues.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null
        },
        [getQues.rejected]: (state, action) => {
            state.error = action.payload
            state.isLoading = false;
        },
    },
})

export default quesApi.reducer;