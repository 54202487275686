import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form } from "react-bootstrap";
import { Fragment } from "react";
import { useGetCollectionsByIdQuery } from "../../../redux/Collections/CollectionsApi";
import { useEditAssessmentTemplateMutation, useGetAssessmentTemplateByIdQuery } from "../../../redux/Assessment/AssessmentApi";
import { useAddAssessmentTemplateMutation, useCreateAssessmentMutation } from "../../../redux/Assessment/AssessmentApi.js";
import { toast } from "react-toastify";
import Header from "../../Header";

const CreateAssessment = () => {
  const navigate = useNavigate()
  const [itemIndex, setItemIndex] = useState( [] );
  const [quesInputs, setquesInputs] = useState( [] );
  const [allSections, setallSections] = useState( [] );
  const [quesFromSections, setQuesFromSections] = useState( [] );
  const [gradeCheck, setgradeCheck] = useState( null );
  const [Gradevalid, setGradevalid] = useState( null );
  const { cbId, templateId } = useParams();
  const { data: collection, isLoading: dataLoading } = useGetCollectionsByIdQuery( { id: cbId } );
  const { data: template, isLoading1: dataLoading1 } = useGetAssessmentTemplateByIdQuery( { id: templateId } );
  let templateLoaded = false
  const [addAssessment] = useCreateAssessmentMutation();
  const [addAssessmentTemplate, { isLoading }] = useAddAssessmentTemplateMutation();
  const [editAssessmentTemplate, { isLoading1 }] = useEditAssessmentTemplateMutation();
  const sections = collection?.results[0]?.sections;
  let secInputs = []
  // save section index
  const handleSectionsIndex = ( index, e ) => {
    setquesInputs( ( prev ) => { return [...prev, parseInt( e )] } )
    let idx = [...itemIndex];
    idx.push( index );
    setItemIndex( idx );
  };
  console.log( 'l', quesInputs, 'p', itemIndex )
  // handle user inputs
  const userIputs = () => {
    document.querySelectorAll( "[name='random_questions']" ).forEach( item => {
      console.log( 'item', item.value )
      secInputs.push( parseInt( item.value ) )
    } )
    console.log( 'secinput', secInputs )
    let data = secInputs.map( ( e, i ) => [e, itemIndex[i]] );
    return data
  }

  //  make random questions
  const randomQuestions = () => {
    let items = [];
    function shuffle( array, idx ) {
      if ( idx <= 0 ) {
        return []
      } else {
        let currentIndex = array.length,
          randomIndex;

        // While there remain elements to shuffle.
        while ( currentIndex !== 0 ) {
          // Pick a remaining element.
          randomIndex = Math.floor( Math.random() * currentIndex );
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
          ];
        }

        return array.slice( 0, idx );
      }
    }
    //for ( const item of userIputs() ) {
    //  console.log( 'us', sections)
    //  items.push( ...shuffle( [...sections[item[1]].questions], item[0] ) );
    //}
    let qfs = secInputs.map( ( e, i ) => { return { "number_of_questions": e ? e : 0, "section_id": sections[i]._id, "section_name": sections[i].name } } )
    console.log( 'qfs', qfs )
    setQuesFromSections( qfs )
    setallSections( items )
  }

  //  validation schema
  const formSchema = Yup.object().shape( {
    time_limit: gradeCheck !== false && Yup.number().positive().required( 'Time Is Required' ),
    attempts_allowed: Yup.number().positive().required( 'Attempts Is Required' ),
    pass_score: Yup.number()
      .positive().max( 100, 'must be less than 100' )
      .required( 'Pass Score Is Required' ),
    title: Yup.string()
      .min( 2, 'Too Short!' )
      .max( 30, 'Too Long!' )
      .required( 'Title Is Required' ),
    description: Yup.string().required( 'Description Is Required' ),
    instructions: Yup.string().required( 'Instructions Is Required' ),
  } );

  //  validate inputs 
  const formik = useFormik( {
    initialValues: {
      time_limit: '',
      pass_score: '',
      title: '',
      description: '',
      instructions: '',
      attempts_allowed: '',
    },
    validationSchema: formSchema,
    validateOnChange: false,
    onSubmit: ( values, { resetForm } ) => {
      values.graded = gradeCheck
      if ( gradeCheck === false ) {
        values.time_limit = ""
      }
      //const LastAssessment = {
      //  ...values,
      //  questions: allSections,
      //  assessment_type: 'standalone'
      //};
      const addAssessTemplate = {
        ...values,
        questions_from_sections: quesFromSections,
        collection_id: cbId
      }
      //addAssessment(LastAssessment)
      console.log( 'u', addAssessTemplate )
      if ( templateId ) {
        editAssessmentTemplate( { ...addAssessTemplate, _id: templateId } )
          .unwrap()
          .then( ( res ) => {
            if ( res.id !== "undefined" ) {
              toast.success( "Edited Successfully" );
              navigate( `/admin` );
              console.log( "response", res )
            }
          } )
          .catch( ( error ) => {
            console.log( error );
            toast.error( "Assessment is not Created" );
          } );
      } else {
        addAssessmentTemplate( addAssessTemplate )
          .unwrap()
          .then( ( res ) => {
            if ( res.id !== "undefined" ) {
              toast.success( "Created Successfully" );
              navigate( `/admin` );
              console.log( "response", res )
            }
          } )
          .catch( ( error ) => {
            console.log( error );
            toast.error( "Assessment is not Created" );
          } );
      }
      resetForm( { values: '' } )
      setallSections( [] )
      setItemIndex( [] );
      setQuesFromSections( [] );

    },
  } );

  const createAssess = ( cbId ) => {
    if ( gradeCheck !== null ) {
      userIputs()
      randomQuestions()
      formik.handleSubmit();
      setItemIndex( [] );
      console.log();
      setquesInputs( [] )
      document.querySelectorAll( "[name='random_questions']" ).forEach( item => item.value = '' )
      console.log( gradeCheck )
      setGradevalid( true )
    } else {
      setGradevalid( false )
    }
  };

  useEffect( () => {
    if ( template && !templateLoaded && collection ) {
      templateLoaded = true
      let res = template?.results[0]
      formik.setFieldValue( 'time_limit', res.time_limit );
      formik.setFieldValue( 'pass_score', res.pass_score );
      formik.setFieldValue( 'title', res.title );
      formik.setFieldValue( 'description', res.description );
      formik.setFieldValue( 'instructions', res.instructions.toString() );
      formik.setFieldValue( 'attempts_allowed', res.attempts_allowed );

      if ( gradeCheck === null && res.questions_from_sections.length ) {
        let eles = document.querySelectorAll( "[name='random_questions']" )
        for ( let i = 0; i < eles.length; i++ ) {
          eles[i].value = res?.questions_from_sections[i]?.number_of_questions
        }
        setgradeCheck( () => res.graded )
      }
    }
  }, [collection, template, templateLoaded, gradeCheck] )

  return (
    <Fragment>

      {template ?
        <Header title={`Edit Assessment`} secTitle={template?.results[0]?.title} path={'/admin'}
          buttons={!dataLoading ? <div className="d-flex justify-content-end">
            <button
              className="btn btn-sm btn-primary me-3  rounded-pill  px-3 "
              onClick={() => createAssess( cbId )}
            >
              {isLoading ? <i className="fas fa-spinner fa-spin"></i> : "Save"}
            </button>
          </div> : <></>} /> :
        <Header title={`Create Assessment`} secTitle={collection?.results[0]?.collection_name} path={'/admin/AssessmentQuestionbanks'}
          buttons={!dataLoading ? <div className="d-flex justify-content-end">
            <button
              className="btn btn-sm btn-primary me-3  rounded-pill  px-3 "
              onClick={() => createAssess( cbId )}
            >
              {isLoading ? <i className="fas fa-spinner fa-spin"></i> : "Save"}
            </button>
          </div> : <></>} />
      }


      <div className="mx-auto px-4 h-85 overflow-y-scroll px-5 py-3">
        {!dataLoading ?
          <Fragment>
            <div className="row">
              <div className="col-xl-4 col-lg-6 mb-3">
                <Form.Label for="flexCheckDefault" className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                  Assessment Type{" "} <span className="text-danger">{Gradevalid === false ? "(required)" : "*"}</span>
                </Form.Label>
                <div className="d-flex mt-2">
                  <div class="form-check me-3">
                    <input type="radio" class="form-check-input" id="validationFormCheck1" checked={gradeCheck} onClick={( e ) => setgradeCheck( true )} name="grade-check" />
                    <label class="form-check-label" for="validationFormCheck1" >Graded</label>

                  </div>
                  <div class="form-check">
                    <input type="radio" class="form-check-input" id="validationFormCheck2" checked={!gradeCheck} onClick={( e ) => setgradeCheck( false )} name="grade-check" />
                    <label class="form-check-label" for="validationFormCheck2">Non-Graded</label>
                  </div>
                </div>
              </div>
              {
                <div className="col-xl-4 col-lg-6 mb-3">
                  <Form.Label className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                    Pass Score(in percentage out of 100) <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="pass score"
                    className="border border-2 border-secondary p-2 rounded shadow-sm"
                    name="pass_score"
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.pass_score}
                    value={formik.values.pass_score}
                    onWheel={event => event.currentTarget.blur()}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.pass_score}
                  </Form.Control.Feedback>
                </div>
              }
              <div className="col-xl-4 col-lg-6 mb-3">
                <Form.Label className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                  Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  className="border border-2 border-secondary p-2 rounded shadow-sm"
                  name="title"
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.title}
                  value={formik.values.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </div>
              <div className="col-xl-4 col-lg-6 mb-3">
                <Form.Label className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                  Instructions ( ',' separate d ) <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Instructions"
                  name="instructions"
                  onChange={formik.handleChange}
                  value={formik.values.instructions}
                  isInvalid={!!formik.errors.instructions}
                  className="border border-2 border-secondary p-2 rounded shadow-sm"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.instructions}
                </Form.Control.Feedback>
              </div>
              <div className="col-xl-4 col-lg-6 mb-3">
                <Form.Label className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                  Attempts Allowed{" "} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="No. of times assesment to be taken"
                  className="border border-2 border-secondary p-2 rounded shadow-sm"
                  name="attempts_allowed"
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.attempts_allowed}
                  value={formik.values.attempts_allowed}
                  onWheel={event => event.currentTarget.blur()}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.attempts_allowed}
                </Form.Control.Feedback>
              </div>
              {gradeCheck !== false && <div className="col-xl-4 col-lg-6 mb-3">
                <Form.Label className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                  Time Limit ( minutes ){" "} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="Time in min"
                  className="border border-2 border-secondary p-2 rounded shadow-sm"
                  name="time_limit"
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.time_limit}
                  value={formik.values.time_limit}
                  onWheel={event => event.currentTarget.blur()}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.time_limit}
                </Form.Control.Feedback>
              </div>}
              <div className="col-xl-8 col-lg-12">
                <Form.Label className="fw-bold fs-6 border-bottom rounded py-2  mb-3 d-inline-block">
                  Description <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Description"
                  className="border p-2 border-2 rounded border-secondary   w-100 mb-3 desc"
                  name="description"
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.description}
                  value={formik.values.description}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
              </div>
            </div>
            {sections?.map( ( section, idx ) => {
              return (
                <div key={idx}>
                  <div>
                    <span className="py-2  my-3  fw-bold d-inline-block">
                      {section.section_name}<span className="text-danger">*</span>
                      <span className="fs-6 fw-light">
                        {" "}
                        ({" "}
                        <span className="fw-bold text-danger">
                          {" "}
                          {section.questions.length}
                        </span>{" "}
                        questions )
                      </span>
                    </span>
                  </div>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder={`Enter no of questions from Sections ${idx + 1} ( Should be equal to or less than number of questions )`}
                    className={`border border-2 rounded shadow-sm border-secondary p-2`}
                    name={`random_questions`}
                    onChange={( e ) => {
                      handleSectionsIndex( idx, e.target.value );
                    }}
                    onWheel={event => event.currentTarget.blur()}
                  />

                </div>
              );
            } )}
          </Fragment> : <div className='vh-100 d-flex justify-content-center align-items-center'>
            <i className="fas fa-spinner fa-spin fa-3x"></i>
          </div>
        }
      </div>
    </Fragment >
  );
};

export default CreateAssessment;
