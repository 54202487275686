import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const AssignmentApi = createApi({
    tagTypes: ['Assignment'],
    reducerPath: 'AssignmentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
    }),

    endpoints: (builder) => ({
        getQuestions: builder.query({
            query: ({ type, page, limit, search }) => `/questionBank/?assessment_type=${type}&page=${page}&limit=${limit}&title=${search}`,
            providesTags: ['Assignment']
        }),
        updateAssessment: builder.mutation({
            query: ({ id, assessment }) => ({ url: `questionBank/${id}/update`, method: 'PUT', body: assessment }), invalidatesTags: ['Assignment']
        }),
        deleteAssessment: builder.mutation({
            query: (_id) => ({ url: `questionBank/${_id}/delete`, method: 'delete' }), invalidatesTags: ['Assignment']
        })
        ,
        addAssessment: builder.mutation({
            query: (newQustion) => ({
                url: `questionBank/add`, method: 'Post',
                body:newQustion
            }), invalidatesTags: ['Assignment']
        }),
    })
})

export const { useGetQuestionsQuery, useUpdateAssessmentMutation, useDeleteAssessmentMutation, useAddAssessmentMutation } = AssignmentApi;
