import { LeftOutlined } from "@ant-design/icons";
import { Col, Radio, Row, Space, Statistic } from "antd";
import { motion } from "framer-motion";
import React, { Fragment, useEffect, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAssess } from "../redux/Assessment/GenerateAssessApi";
import { getQues } from "../redux/Assignment/getQuesApi.js";
import { userAttempts } from "../redux/Attempts/resultAttemptsApi";
import {
	useAddAttemptMutation,
	useAddevaluateMutation,
} from "../redux/Quiz/QuizApi";
import { setAttempts } from "../redux/features/quizSlice";
import { ResultCountdown } from "./index";
const { Countdown } = Statistic;
const { Group } = Radio;
const Quiz = () => {
	// console.log("startcount", startCount)
	const { questionBankId, courseId, userId } = useParams();
	const dispatch = useDispatch();
	let { quest } = useSelector((state) => state.quest);
	let { assessment } = useSelector((state) => state.assessment);
	const { tempLength, attempts } = useSelector((state) => state.resultAttempts);
	// const { data: assessment } = useGenerateAssessmentTemplateQuery(questionBankId)
	const [check, setcheck] = useState(null);
	const [checkCorrect, setcheckCorrect] = useState(null);
	const [showEndCount, setShowEndCount] = useState(null);
	const [valueMcq, setValueMcq] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [tcorrect, setTcorrect] = useState(0);
	const [tquest, setTquest] = useState(0);
	const [score, setScore] = useState(0);
	const [prevCount, setPrevCount] = useState(1);
	const [answerState, setAnswerstate] = useState({ questions: [] });
	const [timer, setTimer] = useState(null);
	const questions = assessment ? assessment?.questions : quest?.questions;
	const questId = assessment ? assessment?.assessment_template_id : quest?._id;
	const time = quest ? quest?.time_limit : 60;
	const atmpt = quest ? quest?.attempts_allowed : 3;
	const graded = quest && quest.graded ? quest.graded : false;
	console.log("graded", graded);
	const { countDawn } = useSelector((state) => state.quizOptions);
	const [addevaluate] = useAddevaluateMutation();
	const [addAttempt] = useAddAttemptMutation();
	const [total_non_graded_correct, settngc] = useState(0);
	useEffect(() => {
		if (courseId)
			dispatch(
				getQues({
					type:
						courseId === undefined
							? `assessmentTemplate`
							: `questionbank/assessment`,
					id: questionBankId,
				})
			);
		dispatch(
			userAttempts({
				type: courseId !== undefined ? `attempt` : `standaloneAttempt`,
				idtype:
					courseId === undefined
						? `assessment_template_id`
						: `question_bank_id`,
				questionBankId,
				userId,
			})
		);
		dispatch(getAssess(questionBankId));
	}, [courseId, questionBankId, userId]);
	console.log(assessment);
	useEffect(() => {
		if (time) {
			setTimer(new Date().setMinutes(new Date().getMinutes() + time));
		}
	}, [time]);
	// useEffect(() => {
	//   if (showEndCount === true) {
	//     const filter = questions.filter(
	//       (quest, i) => quest._id !== answerState?.questions[i]?.questionId
	//     );
	//     let convert = [];
	//     if (filter.length > 0) {
	//       for (let i = 0; i < filter.length; i++) {
	//         convert.push({
	//           questionId: filter[i]._id,
	//           answerId: null,
	//         });
	//       }
	//       let All = { questions: [...convert, ...answerState?.questions] };
	//       console.log("All", All);
	//       addevaluate(All)
	//         .unwrap()
	//         .then((res) => {
	//           setTcorrect(res.total_correct);
	//           setTquest(res.total_questions);
	//           setScore(res.score);
	//           console.log(res);
	//         })
	//         .catch((error) => {
	//           console.log(error);
	//         });
	//       const details = {
	//         assessment_id: questionBankId,
	//         assessment_template_id: questId,
	//         user_id: userId,
	//         graded,
	//         questions: [...All.questions],
	//       };
	//       const atgDetails = {
	//         question_bank_id: questId,
	//         user_id: userId,
	//         course_id: courseId,
	//         graded,
	//         questions: [...All.questions],
	//         assessment_type: quest.courseId ? "" : "atg",
	//       };
	//       console.log(details);
	//       addAttempt({
	//         type: courseId ? "attempt" : "standaloneAttempt",
	//         details: !courseId ? details : atgDetails,
	//       })
	//         .unwrap()
	//         .then((res) => console.log("attempt results", res))
	//         .catch((error) => console.log(error));
	//     }
	//   }

	//   // eslint-disable-next-line no-sparse-arrays
	// }, [
	//   addAttempt,
	//   addevaluate,
	//   answerState,
	//   questId,
	//   questions,
	//   showEndCount,
	//   tcorrect,
	//   time,
	//   tquest,
	//   userId,
	//   courseId,
	//   questionBankId,
	//   quest.courseId,
	// ]);

	dispatch(setAttempts(atmpt));

	const onFinish = () => {
		setShowEndCount(true);
	};

	const handleQuestionClick = () => {
		const nextQuestion = currentQuestion + 1;
		if (currentQuestion < questions.length - 1) {
			setPrevCount((prev) => prev + 1);
			setCurrentQuestion(nextQuestion);
		} else {
			let ans = { ...answerState };
			// if(!graded){
			// addevaluate(ans)
			//   .unwrap()
			//   .then((res) => {
			//     console.log("addevaluate", res);
			//     setTcorrect(res.total_correct);
			//     // setTquest(res.total_questions);
			//     setScore(res.score);
			//   })
			//   .catch((error) => {
			//     console.log(error);
			//   });
			// }
			const details = {
				assessment_id: questionBankId,
				assessment_template_id: questId,
				user_id: userId,
				questions: [...ans.questions],
				graded,
			};
			const atgDetails = {
				question_bank_id: questId,
				user_id: userId,
				course_id: courseId,
				graded,
				questions: [...ans.questions],
				assessment_type: quest.courseId ? "" : "atg",
			};

			if (graded) {
				addAttempt({
					type: courseId ? "attempt" : "standaloneAttempt",
					details: !courseId ? details : atgDetails,
				})
					.unwrap()
					.then((res) => {
						console.log("Attempts result", res);
						if (res.course_id) {
							setTcorrect(res.total_correct);
							setScore(res.score);
						} else {
							setTcorrect(res.score_details.total_correct);
							setScore(res.score_details.total_percentage);
						}
					})
					.catch((error) => console.log(error));

				setShowEndCount(true);
			} else {
				setTcorrect(total_non_graded_correct);
				setShowEndCount(true);
			}
		}
	};

	const handleMcq = (e) => {
		setValueMcq(e.target.value);
	};

	const handleEvaluate = (questionId, answerId, is_correct) => {
		let test = questions.findIndex((e, i) => e._id === answerId);
		setActive(questionId);
		let objectQuestion = {
			questionId: answerId,
			answerId: questionId,
		};
		let ans = { ...answerState };
		// ans.questions.push(objectQuestion);
		// setAnswerstate(ans);
		let filter = answerState.questions.findIndex(
			(e, i) => e.questionId === answerId
		);
		if (filter !== -1) {
			console.log("filter", ans.questions[filter]);
			// setAnswerstate(ans);
			ans.questions[filter] = objectQuestion;
		} else {
			// ans.questions.push(objectQuestion);
			ans.questions.push(objectQuestion);
			// setAnswerstate(ans);
		}
		setAnswerstate(ans);
		console.log("tessssssssst", ans);
		console.log("filter", filter);
		// if (answerState.findIndex((e, i) => e._id === answerId)) {
		//   answerState
		// }
		if (currentQuestion < questions.length - 1) {
			if (graded === false) {
				setcheckCorrect(is_correct);
				if (is_correct) {
					setPrevCount((prev) => prev + 1);
					setCurrentQuestion(currentQuestion + 1);
					setcheck(answerState.questions[test].answerId);
					settngc((prev) => prev + 1);
				}
			} else {
				setPrevCount((prev) => prev + 1);
				setCurrentQuestion(currentQuestion + 1);
				setcheck(
					answerState.questions[answerState.questions.length - 1].answerId
				);
			}
		} else {
			console.log("done", ans);

			// addevaluate(ans)
			//   .unwrap()
			//   .then((res) => {
			//     console.log("addevaluate", res);
			//     setTcorrect(res.total_correct);
			//     // setTquest(res.total_questions);
			//     setScore(res.score);
			//   })
			//   .catch((error) => {
			//     console.log(error);
			//   });

			const details = {
				assessment_id: questionBankId,
				assessment_template_id: questId,
				user_id: userId,
				questions: [...ans.questions],
				graded,
			};
			const atgDetails = {
				question_bank_id: questId,
				user_id: userId,
				course_id: courseId,
				graded,
				questions: [...ans.questions],
				assessment_type: quest.courseId ? "" : "atg",
			};
			if (graded) {
				addAttempt({
					type: courseId ? "attempt" : "standaloneAttempt",
					details: !courseId ? details : atgDetails,
				})
					.unwrap()
					.then((res) => {
						console.log("Attempts result", res);
						if (res.course_id) {
							setTcorrect(res.total_correct);
							setScore(res.score);
						} else {
							setTcorrect(res.score_details.total_correct);
							setScore(res.score_details.total_percentage);
						}
					})
					.catch((error) => console.log(error));

				setShowEndCount(true);
			} else {
				setTcorrect(total_non_graded_correct);
				setShowEndCount(true);
			}
		}
	};

	const handlePrev = () => {
		setPrevCount(0);
		setCurrentQuestion(currentQuestion - 1);
		setcheckCorrect(null);
	};
	const [active, setActive] = useState(null);
	// const handleActive = (i) => {
	//   setActive(i);
	// };
	return (
		<Fragment>
			<div className="count_to_down w-full h-full flex justify-end">
				{countDawn === 0 && showEndCount !== true && graded ? (
					<div className="quiz_timer_box">
						<Countdown
							className="quiz_timer_text"
							onFinish={onFinish}
							value={timer}
						/>
					</div>
				) : (
					<div></div>
				)}
			</div>
			{showEndCount ? (
				<Fragment>
					<ResultCountdown
						result={score}
						total_correct={tcorrect}
						total_questions={
							questions && questions.length ? questions.length : 0
						}
						questionBankId={questionBankId}
						courseId={courseId}
						userId={userId}
						graded={graded}
					/>
				</Fragment>
			) : (
				<Fragment>
					<motion.div
						className={`container-fluid quiz_main_container ${
							graded === false && `mt-5`
						}`}
					>
						<div className="result_header">
							{currentQuestion > 0 &&
							prevCount !== 0 &&
							(checkCorrect === true || checkCorrect === null) ? (
								<div
									className="previous_btn prev_pad cursor-pointer"
									onClick={handlePrev}
								>
									<LeftOutlined className="prev_arrow" />
									Previous Question
								</div>
							) : (
								<div className="disable_previous_btn prev_pad cursor-pointer">
									<LeftOutlined className="prev_arrow" />
									Previous Question
								</div>
							)}
						</div>
						<div className="row mt-4">
							<div className="col quiz_col_main mmc">
								<div className="question_number mmc">
									{
										<h4 className="question_num_text mb-3">
											Question{" "}
											{currentQuestion + 1 < 10
												? (currentQuestion + 1)?.toString().padStart(2, "0")
												: currentQuestion + 1}
											/
											{questions?.length < 10
												? questions?.length?.toString().padStart(2, "0")
												: questions?.length}
										</h4>
									}
								</div>
								<div className="container">
									{((questions &&
										questions[currentQuestion]?.question_type === "text") ||
										questions[currentQuestion]?.question_type ===
											"Question type ?") && (
										<div className="question_text d-block mmc mb-4 quiz ">
											{questions[currentQuestion]?.body.length <= 62 ? (
												<div className="question_text_main ">
													<Linkify as="p">
														{questions[currentQuestion]?.body}
													</Linkify>
												</div>
											) : (
												<div className="question_text_main text-break px-4">
													<Linkify>{questions[currentQuestion]?.body}</Linkify>
												</div>
											)}
										</div>
									)}
									{questions &&
										questions[currentQuestion]?.question_type === "code" && (
											<div className="question-code d-block quiz">
												{questions[currentQuestion]?.body.length >= 62 ? (
													<pre className="question_code_main">
														{questions[currentQuestion]?.body}
													</pre>
												) : (
													<pre className="question_code_main">
														{questions[currentQuestion]?.body}
													</pre>
												)}
											</div>
										)}
									{questions[currentQuestion]?.question_images && (
										<div className="row mb-4 align-items-center justify-content-center">
											{questions[currentQuestion]?.question_images.map(
												(img) => (
													<div className="col-md-4 col-lg-3 col-xl-2">
														<img
															src={img.img}
															alt="question_image"
															className="w-100"
														/>
													</div>
												)
											)}
										</div>
									)}
								</div>
							</div>
						</div>

						{/* Start */}

						{/* End */}
						<div className="container mx-auto my-3">
							<motion.div>
								{questions &&
								questions[currentQuestion]?.answer_type === "image" ? (
									<Fragment>
										<motion.div
											initial={{ opacity: 0, scale: 0.5 }}
											animate={{ opacity: 1, scale: 1 }}
											transition={{ duration: 0.5 }}
										>
											<div className="row row-cols-1 justify-content-center  g-4 mx-auto">
												{questions &&
													questions[currentQuestion]?.answers?.map(
														(answer, i) => (
															<Fragment>
																{checkCorrect === null ||
																checkCorrect === true ? (
																	<div
																		className={`col-md-4 col-lg-3`}
																		key={answer?._id}
																	>
																		<motion.div
																			whileHover={{ scale: 1.01 }}
																			whileTap={{ scale: 0.9 }}
																		>
																			<div
																				className={`rounded-3 overflow-hidden quiz-image position-relative ${
																					check === answer?._id
																						? "quiz-checked"
																						: ""
																				}`}
																				onClick={() =>
																					handleEvaluate(
																						answer?._id,
																						questions[currentQuestion]?._id,
																						answer?.is_correct
																					)
																				}
																			>
																				<div
																					className={`${
																						check !== answer?._id &&
																						prevCount === 0
																							? "quiz-overlay position-relative"
																							: ""
																					}`}
																				>
																					<img
																						src={answer?.answer}
																						className="w-100 h-100"
																						alt="..."
																					/>
																				</div>
																			</div>
																		</motion.div>
																	</div>
																) : checkCorrect === false ? (
																	<div
																		className={`col-md-4 col-lg-3`}
																		key={answer?._id}
																	>
																		<motion.div
																			whileHover={{ scale: 1.01 }}
																			whileTap={{ scale: 0.9 }}
																		>
																			<div
																				className={`rounded-3 overflow-hidden `}
																			>
																				<div
																					className={`${
																						answer?.is_correct ===
																							checkCorrect &&
																						active === answer?._id &&
																						`wrongImage-answer`
																					} position-relative`}
																				>
																					<img
																						src={answer?.answer}
																						className="w-100 "
																						alt="..."
																					/>
																				</div>
																			</div>
																		</motion.div>
																	</div>
																) : (
																	""
																)}
															</Fragment>
														)
													)}
											</div>
											<div className="flex flex-column align-items-center">
												{checkCorrect === false &&
													questions[currentQuestion]
														?.correct_answer_description && (
														<div className="text-center">
															<p>
																{
																	questions[currentQuestion]
																		?.correct_answer_description
																}
															</p>
														</div>
													)}
												{checkCorrect === false &&
													questions[currentQuestion]?.correct_answer_image && (
														<div
															className="text-center flex justify-content-center"
															style={{ width: "600px" }}
														>
															{questions[currentQuestion]
																?.correct_answer_image && (
																<img
																	src={
																		questions[currentQuestion]
																			?.correct_answer_image.img
																	}
																	alt="Correct Answer"
																	className="image-style"
																/>
															)}
														</div>
													)}
											</div>
										</motion.div>
									</Fragment>
								) : questions &&
								  questions[currentQuestion]?.answer_type === "text" ? (
									<motion.div
										initial={{ opacity: 0, scale: 0.5 }}
										animate={{ opacity: 1, scale: 1 }}
										transition={{ duration: 0.5 }}
									>
										<Row
											gutter={[10, 10]}
											className="mmc img_question_row h-100"
										>
											{" "}
											{console.log(
												"f",
												questions[currentQuestion],
												checkCorrect
											)}
											{questions &&
												questions[currentQuestion]?.answers?.map(
													(answer, i) => (
														<Fragment>
															{checkCorrect === null ||
															checkCorrect === true ? (
																<Col
																	xs={24}
																	sm={24}
																	lg={24}
																	key={answer?._id}
																	className="text_question_col"
																>
																	<motion.div
																		className="mcq_questions mmc"
																		whileHover={{ scale: 1.01 }}
																		whileTap={{ scale: 0.9 }}
																	>
																		<Group
																			onChange={handleMcq}
																			value={valueMcq}
																		>
																			<Space
																				direction="vertical"
																				className={` position-relative ${
																					checkCorrect !== false && "checkbox"
																				}`}
																			>
																				<Radio
																					onClick={() =>
																						handleEvaluate(
																							answer?._id,
																							questions[currentQuestion]?._id,
																							answer?.is_correct
																						)
																					}
																					value={answer?._id}
																					className={`mcq_btn makeMeBlue position-relative  p-3 ${
																						answer?.is_correct ===
																							checkCorrect &&
																						active === answer?._id &&
																						`wrong-answer`
																					} ${
																						check === answer?._id &&
																						"quiz-prevchecked"
																					}`}
																				>
																					<h4 className="radio_mcq_text and_me_white ">
																						{answer?.answer}
																					</h4>
																				</Radio>
																			</Space>
																		</Group>
																	</motion.div>
																</Col>
															) : checkCorrect === false ? (
																<Col
																	xs={24}
																	sm={24}
																	lg={24}
																	key={answer?._id}
																	className="text_question_col"
																>
																	<motion.div
																		className="mcq_questions mmc"
																		whileHover={{ scale: 1.01 }}
																		whileTap={{ scale: 0.9 }}
																	>
																		<Group
																			onChange={handleMcq}
																			value={valueMcq}
																		>
																			<Space
																				direction="vertical"
																				className={` position-relative ${
																					checkCorrect !== false && "checkbox"
																				}`}
																			>
																				<Radio
																					value={answer?._id}
																					className={`mcq_btn  position-relative  p-3 ${
																						answer?.is_correct ===
																							checkCorrect &&
																						active === answer?._id &&
																						`wrong-answer`
																					} ${
																						check === answer?._id &&
																						"quiz-prevchecked"
																					}`}
																					disabled
																				>
																					<h4 className="radio_mcq_text ">
																						{answer?.answer}
																					</h4>
																				</Radio>
																			</Space>
																		</Group>
																	</motion.div>
																</Col>
															) : (
																""
															)}
														</Fragment>
													)
												)}
											<div className="flex flex-column align-items-center">
												{checkCorrect === false &&
													questions[currentQuestion]
														?.correct_answer_description && (
														<div className="text-center">
															<p>
																{
																	questions[currentQuestion]
																		?.correct_answer_description
																}
															</p>
														</div>
													)}
												{checkCorrect === false &&
													questions[currentQuestion]?.correct_answer_image && (
														<div
															className="text-center flex justify-content-center"
															style={{ width: "600px" }}
														>
															{questions[currentQuestion]
																?.correct_answer_image && (
																<img
																	src={
																		questions[currentQuestion]
																			?.correct_answer_image.img
																	}
																	alt="Correct Answer"
																	className="image-style"
																/>
															)}
														</div>
													)}
											</div>
										</Row>
									</motion.div>
								) : (
									<motion.div
										initial={{ opacity: 0, scale: 0.5 }}
										animate={{ opacity: 1, scale: 1 }}
										transition={{ duration: 0.5 }}
									>
										<div className="container text-center m_auto ">
											<div
												className={`row ${
													checkCorrect === false
														? `justify-content-center`
														: `justify-content-between`
												} gap-y-7`}
											>
												{questions &&
													questions[currentQuestion]?.answers?.length === 2 && (
														<Fragment>
															{questions[currentQuestion]?.answers?.map(
																(answer, i) => (
																	<Fragment>
																		{checkCorrect === null ||
																		checkCorrect === true ? (
																			<div
																				key={answer?._id}
																				className={`col-5 code_answers ${
																					check !== answer?._id &&
																					prevCount === 0
																						? "quiz-code"
																						: ""
																				}`}
																			>
																				<motion.div
																					className={`code_question_box position-relative ${
																						check === answer?._id
																							? "quiz-checked"
																							: check !== answer?._id &&
																							  prevCount === 0
																							? "quiz-code"
																							: ""
																					}`}
																					whileHover={{ scale: 1.04 }}
																					whileTap={{ scale: 1.04 }}
																					onClick={() =>
																						handleEvaluate(
																							answer?._id,
																							questions[currentQuestion]?._id,
																							answer?.is_correct
																						)
																					}
																				>
																					<pre className="">
																						<code className="code_style ">
																							{answer?.answer
																								? answer?.answer
																								: ""}
																						</code>
																					</pre>
																				</motion.div>
																			</div>
																		) : checkCorrect === false ? (
																			<div
																				key={answer?._id}
																				className={`col-5 code_answers`}
																			>
																				<motion.div
																					className={`code_question_box position-relative ${
																						answer?.is_correct ===
																							checkCorrect &&
																						active === answer?._id &&
																						` wrongImage-answer`
																					} `}
																					whileHover={{ scale: 1.04 }}
																					whileTap={{ scale: 1.04 }}
																					onClick={() =>
																						handleEvaluate(
																							answer?._id,
																							questions[currentQuestion]?._id,
																							answer?.is_correct
																						)
																					}
																				>
																					<pre>
																						<code className="code_style ">
																							{answer?.answer
																								? answer?.answer
																								: ""}
																						</code>
																					</pre>
																				</motion.div>
																			</div>
																		) : (
																			""
																		)}
																	</Fragment>
																)
															)}
															<div className="flex flex-column align-items-center">
																{checkCorrect === false &&
																	questions[currentQuestion]
																		?.correct_answer_description && (
																		<div className="text-center">
																			<p>
																				{
																					questions[currentQuestion]
																						?.correct_answer_description
																				}
																			</p>
																		</div>
																	)}
																{checkCorrect === false &&
																	questions[currentQuestion]
																		?.correct_answer_image && (
																		<div
																			className="text-center flex justify-content-center"
																			style={{ width: "600px" }}
																		>
																			{questions[currentQuestion]
																				?.correct_answer_image && (
																				<img
																					src={
																						questions[currentQuestion]
																							?.correct_answer_image.img
																					}
																					alt="Correct Answer"
																					className="image-style"
																				/>
																			)}
																		</div>
																	)}
															</div>
														</Fragment>
													)}
												{questions &&
													questions[currentQuestion]?.answers?.length >= 3 && (
														<Fragment>
															{questions[currentQuestion]?.answers?.map(
																(answer, i) => (
																	<Fragment>
																		{checkCorrect === null ||
																		checkCorrect === true ? (
																			<div
																				key={answer?._id}
																				className={`col-5 code_answers ${
																					check !== answer?._id &&
																					prevCount === 0
																						? "quiz-code"
																						: ""
																				}`}
																			>
																				<motion.div
																					className={`code_question_box position-relative ${
																						check === answer?._id
																							? "quiz-checked"
																							: check !== answer?._id &&
																							  prevCount === 0
																							? "quiz-code"
																							: ""
																					}`}
																					whileHover={{ scale: 1.04 }}
																					whileTap={{ scale: 1.04 }}
																					onClick={() =>
																						handleEvaluate(
																							answer?._id,
																							questions[currentQuestion]?._id,
																							answer?.is_correct
																						)
																					}
																				>
																					<pre className="">
																						<code className="code_style ">
																							{answer?.answer
																								? answer?.answer
																								: ""}
																						</code>
																					</pre>
																				</motion.div>
																			</div>
																		) : checkCorrect === false ? (
																			<div
																				key={answer?._id}
																				className={`col-5 code_answers`}
																			>
																				<motion.div
																					className={`code_question_box position-relative  ${
																						answer?.is_correct ===
																							checkCorrect &&
																						active === answer?._id &&
																						` wrongImage-answer`
																					}`}
																					whileHover={{ scale: 1.04 }}
																					whileTap={{ scale: 1.04 }}
																					onClick={() =>
																						handleEvaluate(
																							answer?._id,
																							questions[currentQuestion]?._id,
																							answer?.is_correct
																						)
																					}
																				>
																					<pre className="">
																						<code className="code_style ">
																							{answer?.answer
																								? answer?.answer
																								: ""}
																						</code>
																					</pre>
																				</motion.div>
																			</div>
																		) : (
																			""
																		)}
																	</Fragment>
																)
															)}
															<div className="flex flex-column align-items-center">
																{checkCorrect === false &&
																	questions[currentQuestion]
																		?.correct_answer_description && (
																		<div className="text-center">
																			<p>
																				{
																					questions[currentQuestion]
																						?.correct_answer_description
																				}
																			</p>
																		</div>
																	)}
																{checkCorrect === false &&
																	questions[currentQuestion]
																		?.correct_answer_image && (
																		<div
																			className="text-center flex justify-content-center"
																			style={{ width: "600px" }}
																		>
																			{questions[currentQuestion]
																				?.correct_answer_image && (
																				<img
																					src={
																						questions[currentQuestion]
																							?.correct_answer_image.img
																					}
																					alt="Correct Answer"
																					className="image-style"
																				/>
																			)}
																		</div>
																	)}
															</div>
														</Fragment>
													)}

												{/* {questions &&
                          questions[currentQuestion]?.answers?.length >= 4 && (
                            <Fragment>
                              {questions[currentQuestion]?.answers?.map(
                                (answer) => (
                                  <div
                                    key={answer?._id}
                                    className="col-3 code_answers"
                                  >
                                    <motion.div
                                      className="code_question_box"
                                      whileHover={{ scale: 1.04 }}
                                      whileTap={{ scale: 1.04 }}
                                      onClick={() =>
                                        handleEvaluate(
                                          answer?._id,
                                          questions[currentQuestion]?._id,
                                          answer?.is_correct
                                        )
                                      }
                                    >
                                      <pre>
                                        <code className="code_style">
                                          {answer?.answer ? answer?.answer : ""}
                                        </code>
                                      </pre>
                                    </motion.div>
                                  </div>
                                )
                              )}
                              {checkCorrect === false &&
                                questions[currentQuestion]
                                  ?.correct_answer_description && (
                                  <p className="text-center">
                                    {
                                      questions[currentQuestion]
                                        ?.correct_answer_description
                                    }
                                  </p>
                                )}
                            </Fragment>
                          )} */}
											</div>
										</div>

										{/* <Row gutter={[10, 10]} className="mmc code_ques_row code_question_row">
                    {questions[currentQuestion]?.answers?.map(answer => (
                      <Col xs={24} sm={24} md={24} lg={12} xl={6} key={answer?._id} className="image_question_col  mmc code_col gutter-row"
                      >
                        <motion.div className="code_question_box"
                          whileHover={{ scale: 1.04 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleEvaluate(answer?._id, questions[currentQuestion]?._id,answer?.is_correct)}
                        >
                          <pre>
                            <code className="code_style">
                              {answer?.answer ? parse(answer?.answer) : ''}
                            </code>
                          </pre>

                        </motion.div>
                      </Col>
                    ))}
                  </Row> */}
									</motion.div>
								)}
							</motion.div>
						</div>

						<Row gutter={[10, 10]} className="mmc skip_this_question_row mt-16">
							<Col xs={24} sm={24} lg={6} className="skip_this_question_col">
								<div className="skip_quest mmc">
									<p
										onClick={() => handleQuestionClick()}
										className={`skip_me cursor-pointer ${
											checkCorrect === false && "d-none"
										}`}
									>
										Skip this question
									</p>
									{checkCorrect === false && (
										<button
											type="btn"
											className="btn btn-primary ms-3"
											onClick={() => {
												handleQuestionClick();
												setcheckCorrect(null);
											}}
										>
											Next
										</button>
									)}
								</div>
							</Col>
						</Row>
					</motion.div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Quiz;
