import React, { Fragment } from 'react'
import SideNav from './SideNavbar/SideNav'
import NavBarTest from "./NavBarTest.jsx"

export default function TestDesign() {
    return (
        <Fragment>
            <div className='row '>
                <div className='d-lg-none d-block py-2 px-5'>
                    <NavBarTest />
                </div>
                <div className='col-xl-2 col-lg-3 d-lg-block d-none pe-0 ps-0'>
                    <div className='bg-secondary'>
                        <SideNav />
                    </div>
                </div>
                <div className='col-xl-10 col-lg-9 pe-0 ps-0 overflow-hidden'>
                    <div className='text-white fs-1  bg-black p-3'>Amer</div>
                </div>
            </div>
        </Fragment>
    )
}
