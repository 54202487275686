import React, { Fragment, useState } from "react";
import { BsPlusSquareDotted } from "react-icons/bs";
import { Button } from "antd";
import { Input } from "antd";
import { Form } from "react-bootstrap";
import { DeleteOutlined } from "@ant-design/icons";
import Modal from "react-bootstrap/Modal";
import TextArea from "antd/lib/input/TextArea";
import ReactQuill from "react-quill";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "react-quill/dist/quill.snow.css";
const UpdateForm = ({
  HandleImageAnswer,
  removeImgOption,
  handleImageQuestchange,
  AddImageQuest,
  gradeCheck,
  setBodyfun,
  handleCorrectchange,
  questionIndex,
  question,
  RemoveQusFiled,
  handleFromchange,
  handleFromAnswerchange1,
  setQuestions,
  handleFromAnswerchange,
  AddOp,
  rmve,
  problemsType,
  problems,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { question_type, answer_type, body, id } = question;
  // const [value, setValue] = useState(body)
  const [showModalImg, setShowModalImg] = useState("");
  const { answers } = question;
  // setBodyfun(value)
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  return (
    <div>
      <div className="mt-4 mb-4" key={id}>
        <div className="flex justify-between align-items-center">
          <h3 className="py-2 px-3 border border-1 rounded fs-6 fw-bold mb-0">
            Question No : {questionIndex + 1}
          </h3>
          <div className="flex justify-content-between align-items-center my-3">
            <button
              type="button"
              class="btn btn-primary rounded-pill   fs-6 shadow-sm me-2"
              onClick={() => AddOp(questionIndex)}
            >
              Add Option{" "}
            </button>
            <button
              type="button"
              class="btn btn-outline-danger rounded-pill   fs-6 shadow-sm"
              onClick={() => RemoveQusFiled(questionIndex)}
            >
              Remove Question
            </button>
          </div>
        </div>
        <div className="flex">
          <select
            className="select fw-bold p-2 border-secondary  rounded shadow-sm border border-1 w-50 me-1 mb-4 shadow-md"
            name="question_type"
            onChange={(e) => handleFromchange(e, questionIndex)}
            value={question_type}
          >
            <option selected>Question type ?</option>
            <option>text</option>
            <option>code</option>
          </select>
          <select
            className="select fw-bold p-2 border-secondary  rounded shadow-sm border border-1 ms-1 w-50 mb-4 shadow-md"
            name="answer_type"
            onChange={(e) => handleFromchange(e, questionIndex)}
            value={answer_type}
          >
            <option selected>Answer type ?</option>
            <option>text</option>
            <option>image</option>
            <option>code</option>
          </select>
        </div>
        {/* <Input type="text" placeholder={`Question`} value={body} className="input rounded-none pl-2 input-bordered w-100 h-[150px] mb-4 shadow-md" name='body' onChange={(e) => handleFromchange(e, questionIndex)} /> */}
        <textarea
          rows={`${body.split("\n").length}`}
          cols="90"
          type="text"
          placeholder={`Question`}
          className="input p-2  border border-1 w-100 mb-3 shadow-sm border-secondary p-2 rounded"
          name="body"
          value={body}
          onChange={(e) => handleFromchange(e, questionIndex)}
        />
        {/* <div className="d-flex align-items-center justify-content-between mb-3">
          <h4 class="mb-0">Include Images in Question</h4>
          <div>
            <button
              type="button"
              class="btn btn-primary rounded-pill my-3  fs-6 shadow-sm me-2"
              onClick={() => AddImageQuest(questionIndex)}
            >
              Add Image Question{" "}
            </button>
          </div>
        </div> */}
        {question?.question_images.map((img, imQuestIndex) => {
          return (
            <Fragment key={imQuestIndex}>
              <div className="d-flex">
                {img.img ? (
                  <span onClick={() => setShowModalImg(img.img)}>
                    <Zoom>
                      <img alt="" src={img.img} width="200" height="120" />
                    </Zoom>
                  </span>
                ) : (
                  <Input
                    type="file"
                    placeholder="Image Question"
                    className="input p-2 my-2 rounded-none input-bordered w-full  drop-shadow-md  border-secondary"
                    name={"question_image"}
                    onChange={(event) =>
                      handleImageQuestchange(event, imQuestIndex, questionIndex)
                    }
                  />
                )}
                <button
                  type="button"
                  class="btn"
                  onClick={() => removeImgOption(imQuestIndex, questionIndex)}
                >
                  <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                </button>
              </div>
            </Fragment>
          );
        })}
        <div>
          {problems.map((e, i) =>
            e === questionIndex ? (
              <p className="alert alert-danger">
                {problemsType[i] === "Question type ?"
                  ? " Select Question Type For This Question"
                  : "Select One Correct Answer for This Question"}{" "}
              </p>
            ) : null
          )}
        </div>
        {/*<ReactQuill name="body" theme="snow" value={value} onChange={setValue} modules={modules} formats={formats} className=" rounded border-secondary " />*/}
        {question?.answer_type === "text" && (
          <Fragment>
            {answers?.map((answer, answerIndex) => {
              return (
                <div className="flex align-center mt-2">
                  <select
                    className="select rounded-none border border-1 shadow-md"
                    name={"is_correct"}
                    onChange={(e) =>
                      handleCorrectchange(e, answerIndex, questionIndex)
                    }
                  >
                    {answer.is_correct ? (
                      <Fragment>
                        <option>false</option>
                        <option selected>true</option>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <option defaultValue={true}>false</option>
                        <option>true</option>
                      </Fragment>
                    )}
                  </select>
                  <textarea
                    rows={`${answer.answer.split("\n").length}`}
                    type="text"
                    placeholder="option"
                    className="p-2 border-secondary input option_inp ms-5 rounded-none input-bordered w-100 pl-2 h-[42px] shadow-md"
                    name={"answer" + answer.is_correct}
                    value={answer.answer}
                    onChange={(e) =>
                      handleFromAnswerchange(e, answerIndex, questionIndex)
                    }
                  />
                  <button
                    type="button"
                    class="btn"
                    onClick={() => rmve(questionIndex, answerIndex)}
                  >
                    <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                  </button>
                </div>
              );
            })}
          </Fragment>
        )}
        {question?.answer_type === "code" && (
          <Fragment>
            {answers?.map((answer, answerIndex) => {
              return (
                <div className="flex align-center mt-2">
                  <select
                    className="select rounded-none border border-1 shadow-md"
                    name={"is_correct"}
                    onChange={(e) =>
                      handleCorrectchange(e, answerIndex, questionIndex)
                    }
                  >
                    {answer.is_correct ? (
                      <Fragment>
                        <option>false</option>
                        <option selected>true</option>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <option defaultValue={true}>false</option>
                        <option>true</option>
                      </Fragment>
                    )}
                  </select>
                  <textarea
                    rows={`${answer.answer.split("\n").length}`}
                    cols="90"
                    type="text"
                    placeholder="option"
                    className="input p-2 option_inp  input-bordered w-100 mb-4 shadow-md mb-0 ms-2"
                    name={"answer" + answer.is_correct}
                    value={answer.answer}
                    onChange={(e) =>
                      handleFromAnswerchange(e, answerIndex, questionIndex)
                    }
                  />
                  <button
                    type="button"
                    class="btn"
                    onClick={() => rmve(questionIndex, answerIndex)}
                  >
                    <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                  </button>
                </div>
              );
            })}
          </Fragment>
        )}
        {question?.answer_type === "image" && (
          <>
            {answers?.map((answer, answerIndex) => {
              return (
                <div className="flex align-center mt-2">
                  <select
                    className="select align-self-start py-2 mt-2 rounded-none border border-1 shadow-md"
                    name={"is_correct"}
                    onChange={(e) =>
                      handleCorrectchange(e, answerIndex, questionIndex)
                    }
                  >
                    {answer.is_correct ? (
                      <Fragment>
                        <option>false</option>
                        <option selected>true</option>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <option defaultValue={true}>false</option>
                        <option>true</option>
                      </Fragment>
                    )}
                  </select>
                  {!answer._id ? (
                    <Input
                      type="file"
                      className="border-secondary input option_inp ms-5 rounded-none input-bordered w-100 pl-2 h-[42px] shadow-md"
                      name={"answer" + answer.is_correct}
                      onChange={(e) =>
                        handleFromAnswerchange1(e, answerIndex, questionIndex)
                      }
                    />
                  ) : (
                    <></>
                  )}

                  {answer._id ? (
                    <span onClick={() => setShowModalImg(answer.answer)}>
                      {/*  <button type="button" class="btn btn-primary ms-3 me-2" onClick={() => setShowModal(true)}>
                                            <img src={answer.answer} alt="" class="popup-image" />
                                </button>*/}
                      <Zoom>
                        <img alt="" src={answer.answer} width="200" />
                      </Zoom>
                    </span>
                  ) : (
                    <></>
                  )}
                  <button
                    type="button"
                    class="btn"
                    onClick={() => rmve(questionIndex, answerIndex)}
                  >
                    <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                  </button>
                </div>
              );
            })}
            <Fragment>
              {showModal ? (
                <>
                  {/* <Modal
                                    className="overflow-hidden"
                                    show={showModal}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Body>
                                        <img src={showModalImg} alt="" class="w-50" />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)}>Close</button>
                                    </Modal.Footer>
                            </Modal> */}
                </>
              ) : null}
            </Fragment>
          </>
        )}
        {question?.answers.length >= 2 && (
          <Fragment>
            {gradeCheck === false && (
              <Fragment>
                <Form.Label className="fs-6 mt-4">
                  Correct Answer Description :
                </Form.Label>
                <textarea
                  rows="2"
                  cols="90"
                  type="text"
                  value={question?.correct_answer_description}
                  placeholder={`Correct Answer Description`}
                  className="input p-2  border border-1 w-100 mb-3 shadow-sm border-secondary mt-3"
                  name="correct_answer_description"
                  onChange={(e) => handleFromchange(e, questionIndex)}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default UpdateForm;
