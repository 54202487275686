import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  time: 0,
  attempts: 0,
  countDawn: 5,
  generatedLinks: {},
  quizType: ""
};

const quizSlice = createSlice({
  name: "quizOptions",
  initialState,
  reducers: {
    setTime: (state, action) => {
      let mainTime = action.payload * 60
      state.time = mainTime;
    },

    setAttempts: (state, action) => {
      state.attempts = action.payload
    },
    setCountDawn: (state, action) => {
      state.countDawn = action.payload
    },
    setquizType: (state, action) => {
      state.quizType = action.payload
    },
    setGeneratedLinks: (state, action) => {
      state.generatedLinks = action.payload
    }
  },
});

export const { setTime, setAttempts, setCountDawn, setTemps, setGeneratedLinks, setquizType } = quizSlice.actions;

export default quizSlice.reducer;