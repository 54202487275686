import React, { useEffect, useState } from "react";
import { BiHome } from "react-icons/bi";
import { GiReturnArrow } from "react-icons/gi";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { Fragment } from "react";
import { Form } from "react-bootstrap";
import {
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
  useGetCollectionsByIdQuery,
} from "../../redux/Collections/CollectionsApi";
import UpdateForm from "./UpdateForm";
import Header from "../Header";
const UpdateCollection = () => {
  // const [answers, setAnswers] = useState([[[]]])//handle multiple ans
  const [collection, setCollection] = useState([]);
  const [sections, setSections] = useState([]);
  const [delQues, setDelQues] = useState([]);
  const [problems, setProblems] = useState([]);
  const [problemsType, setProblemsType] = useState([]);
  const [problemsTypeSec, setProblemsTypeSec] = useState([]);
  const [imgAnswer, setimgAnswer] = useState(false);
  const navigate = useNavigate();
  let [deleteCollection, { isLoading: isDeleting }] =
    useDeleteCollectionMutation();
  let [updateCollection, { isLoading: isUpdating }] =
    useUpdateCollectionMutation();
  const { cbId } = useParams();
  let { data, isLoading } = useGetCollectionsByIdQuery({ id: cbId });
  useEffect(() => {
    if (data) {
      const clone = JSON.parse(JSON.stringify(data?.results[0]));
      setCollection(clone);
      setSections(clone?.sections);
    }
  }, [data]);
  const returnSection = () => {
    return {
      section_name: "",
      questions: [],
      // section_id: collection._id,
    };
  };
  const AddQusFiled = (sectionIndex) => {
    const data = [...sections];
    const Newqus = {
      question_type: "",
      body: "",
      answers: [],
      answer_type: "",
      question_images: [],
      section_id: "",
      createdAt: Date.now(),
      __v: 0,
    };
    data[sectionIndex].questions.push(Newqus);
    setSections(data);
  };
  const AddOp = (sectionIndex, questionId) => {
    if (
      sections[sectionIndex].questions[questionId].answer_type &&
      sections[sectionIndex].questions[questionId].question_type
    ) {
      const data = [...sections];
      const newOption = {
        answer: "",
        is_correct:
          data[sectionIndex].questions[questionId].answers.find(
            (e) => e.is_correct === true
          ) === undefined
            ? true
            : false,
      };
      data[sectionIndex].questions[questionId].answers.push(newOption);
      setSections(data);
    } else {
      toast.info("Please Select The Option Type");
    }
  };

  const rmve = (answerIndex, questionIndex, sectionIndex) => {
    const data = [...sections];

    const items = data[sectionIndex].questions[answerIndex].answers.filter(
      (answer, idx) => {
        return idx !== questionIndex;
      }
    );
    data[sectionIndex].questions[answerIndex].answers = items;
    setSections(data);
    console.log(items);
  };

  const rmveCAI = (questionIndex, sectionIndex) => {
    const data = [...sections];

    data[sectionIndex].questions[questionIndex].correct_answer_image = {img:""}
    setSections(data);
  };

  const addSection = () => {
    const data = [...sections];
    const newSection = returnSection();
    data.push(newSection);
    setSections(data);
  };

  const heandalQuizinfochange = (event, sectionIndex) => {
    let data = [...sections];
    data[sectionIndex].section_name = event.target.value;
    setSections(data);
  };
  const handleFromchange = (event, index, mainIndex) => {
    let data = [...sections];
    // console.log(data)
    data[mainIndex].questions[index][event.target.name] = event.target.value;
    // data[index].answers[index].answer = event.target.value
    setSections(data);
  };
  const handleCorrectchange = (event, idx, index, mainIndex) => {
    let data = [...sections];
    data[mainIndex].questions[index].answers[idx][event.target.name] =
      JSON.parse(event.target.value);
    // data[index].answers[index].answer = event.target.value
    console.log(data);
    setSections(data);
  };
  const checkQuestionsOptions = () => {
    // setProblems([])
    let values = [];
    let data = [];
    let dataOfSections = [];
    let s = 0;
    let i = 0;
    for (const section of sections) {
      for (const quest of section.questions) {
        if (quest.answers.find((e) => e.is_correct === true) === undefined) {
          values.push(false);
          data.push(i);
          dataOfSections.push(s);
        }
        if (quest.question_type === "Question type ?") {
          values.push(quest.question_type);
          data.push(i);
          dataOfSections.push(s);
        }
        if (quest.correct_answer_description === "") {
          values.push("empty_correct_answer");
          data.push(i);
          dataOfSections.push(s);
        }
        i++;
      }
      i = 0;
      s++;
    }
    setProblemsTypeSec(dataOfSections);
    setProblems(data);
    setProblemsType(values);
    values = values.find(
      (e) =>
        e === "Question type ?" || e === false || e === "empty_correct_answer"
    );
    return values;
  };
  const handleFromAnswerchange = (
    event,
    answerIndex,
    questionIndex,
    sectionIndex
  ) => {
    let data = [...sections];
    data[sectionIndex].questions[questionIndex].answers[answerIndex].answer =
      event.target.value;
    setSections(data);
    // console.log(sections);
    // console.log(event.target.value);
  };
//  useEffect(()=>{ console.log(sections)},[sections])
  const handleFromAnswerchange1 = (
    event,
    answerIndex,
    questionIndex,
    sectionIndex
  ) => {
    if (
      sections[sectionIndex].questions[questionIndex].answer_type === "image"
    ) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const string = reader.result.toString();
        let data = [...sections];
        data[sectionIndex].questions[questionIndex].answers[
          answerIndex
        ].answer = string;
        setSections(data);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };
  const RemoveSection = (sectionIndex) => {
    const newArray = [...sections];
    newArray.splice(sectionIndex, 1);
    setSections(newArray);
  };
  const RemoveQusFiled = (sectionIndex, index) => {
    const newArray = [...sections];
    const deleted = [...delQues];
    deleted.push(newArray[sectionIndex].questions[index]._id);
    setDelQues(deleted);
    newArray[sectionIndex].questions.splice(index, 1);
    setSections(newArray);
  };

  let url = process.env.REACT_APP_API_BASE_URL;
  const handleDelete = (_id) => {
    deleteCollection(_id)
      .unwrap()
      .then((res) => {
        toast.success("Deleted successfully");
        navigate("/admin/AssessmentQuestionbanks");
      })
      .catch((error) => {
        console.log(error);
        toast.info("Error Deleting Assessment Questionbank");
      });
    // console.log(sections)
    // checkQuestionsOptions()

    // console.log("sec Num", problemsTypeSec)
    // console.log("ques num", problems)
    // console.log(problemsType)
  };

  const onSave = (_id) => {
    let url = process.env.REACT_APP_API_BASE_URL;
    const newSec = [...sections];
    const newcollection = { ...collection };
    newcollection.sections = newSec;
    const AllData = {
      ...newcollection,
      deleted_questions: delQues,
    };
    setCollection(newcollection);
    if (checkQuestionsOptions() === undefined) {
      updateCollection({ _id, collection: AllData })
        .unwrap()
        .then((res) => {
          toast.success("Assessment QuestionsBank  updated");
          navigate("/admin/AssessmentQuestionbanks");
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Assessment QuestionsBank Is Not updated");
        });
    } else if (checkQuestionsOptions() === "Question type ?") {
      toast.error("Please Select Question Type For Each Question");
    } else if (checkQuestionsOptions() === false) {
      toast.error("Please Select One Correct Answer for Each Question");
    }
  };
  const AddImageQuest = (mainIndex, index) => {
    const data = [...sections];
    const newImg = {
      img: "",
    };
    data[mainIndex].questions[index].question_images.push(newImg);
    setSections(data);
  };
  const handleImageQuestchange = (
    event,
    imgQuestIndex,
    qusIndex,
    mainIndex
  ) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const string = reader.result.toString();
      let data = [...sections];
      data[mainIndex].questions[qusIndex].question_images[imgQuestIndex].img =
        string;
      setSections(data);
    };
    reader.readAsDataURL(file);
  };

  const handleCorrectAnswerImage = (
    event,
    qusIndex,
    mainIndex
  ) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const string = reader.result.toString();
      let data = [...sections];
      data[mainIndex].questions[qusIndex].correct_answer_image = { img:string }
      setSections(data);
    };
    reader.readAsDataURL(file);
  };

  const removeImgOption = (imQuestIndex, questionIndex, mainIndex) => {
    const data = [...sections];

    const items = data[mainIndex].questions[
      questionIndex
    ].question_images.filter((answer, idx) => {
      return idx !== imQuestIndex;
    });

    data[mainIndex].questions[questionIndex].question_images = items;
    setSections(data);
  };
  const HandleImageAnswer = (mainIndex, qusIndex) => {
    const data = [...sections];
    setimgAnswer(!imgAnswer);
    console.log("checkbox", imgAnswer);
    data[mainIndex].questions[qusIndex].question_images = [];
    setSections(data);
  };
  return (
    <div className="h-100">
      <Header
        title={`Update Assessment Questionbank`}
        secTitle={`Update Assessment Questionbank`}
        path={"/admin/AssessmentQuestionbanks"}
        buttons={
          !isLoading ? (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-sm btn-primary rounded-pill me-3 px-3"
                onClick={() => onSave(cbId)}
              >
                {isUpdating ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  "Save"
                )}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger rounded-pill px-3"
                onClick={() => handleDelete(cbId)}
              >
                {isDeleting ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          ) : (
            <></>
          )
        }
      />
      <div className="mx-auto px-5 py-3 flex-md-row flex-column h-85 overflow-y-scroll">
        {!isLoading ? (
          <div>
            <div className="mb-5">
              <Form.Label className="fs-6 fw-bold">
                Collection Name <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                value={collection?.collection_name}
                type="text"
                placeholder="Collection Name"
                name="collection_name"
                onChange={(e) =>
                  setCollection({
                    ...collection,
                    collection_name: e.target.value,
                  })
                }
                className="border border-2 rounded mt-2 mb-3 w-50 border-secondary p-2 "
              />
            </div>
            <form action={onSubmit}>
              <>
                {sections?.map((section, sectionIndex) => (
                  <UpdateForm
                    AddImageQuest={AddImageQuest}
                    imgAnswer={imgAnswer}
                    removeImgOption={removeImgOption}
                    handleImageQuestchange={handleImageQuestchange}
                    handleCorrectAnswerImage ={handleCorrectAnswerImage}
                    HandleImageAnswer={HandleImageAnswer}
                    problemsType={problemsType}
                    problemsTypeSec={problemsTypeSec}
                    problems={problems}
                    addSection={addSection}
                    section={section}
                    sectionIndex={sectionIndex}
                    handleFromAnswerchange={handleFromAnswerchange}
                    RemoveQusFiled={RemoveQusFiled}
                    AddQusFiled={AddQusFiled}
                    RemoveSection={RemoveSection}
                    handleFromchange={handleFromchange}
                    heandalQuizinfochange={heandalQuizinfochange}
                    setSections={setSections}
                    rmve={rmve}
                    rmveCAI ={rmveCAI}
                    AddOp={AddOp}
                    handleFromAnswerchange1={handleFromAnswerchange1}
                    handleCorrectchange={handleCorrectchange}
                  />
                ))}
              </>
            </form>
          </div>
        ) : (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <i className="fas fa-spinner fa-spin fa-3x"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateCollection;