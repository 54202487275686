import './App.css';
import { Fragment, useEffect } from 'react';
import { Routes, Route, useParams } from "react-router-dom";
import { StartingCountdown, Quiz } from './pages/index'
import Admin from './Components/MainAdminPanel/Admin';
import { Assignment, AddAssignment, UpdateAssignment } from './Components/index.js'
import { Collections, UpdateCollection, AddCollection } from './Components/index';
import { CreateAssessment, GenerateAssessment } from './Components/index';
import AddFrom from './Components/Assignment/AddForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Attempts from './Components/Attempts/Attempts';
import AssessmentQuiz from './pages/AssessmentQuiz';
import { useSelector } from 'react-redux';
import AtgAssignment from './Components/Assignment/AtgAssignment';
import TestDesign from './Components/TestDesign';
import Login from './Components/Login/Login';
import SendAssessment from "./Components/Assessment/SendAssessment/SendAssessment.jsx";
import UsersReport from './Components/Users/UsersReport.jsx';
import UserReportData from './Components/Users/UserReportData.jsx';

function App() {
  const { countDawn } = useSelector((state) => state.quizOptions)
  return (
    <div className="app container-fluid">
      <Routes>
        <Route path="/standalone/:questionBankId/:userId" element={<AssessmentQuiz />} />
        {countDawn !== 0 ? (<Fragment>
          <Route path="/start/:questionBankId/:userId" element={<StartingCountdown />} />
        </Fragment>
        ) : (<Fragment>
          <Route path="/start/:questionBankId/:userId" element={<Quiz />} />
        </Fragment>
        )}
        {countDawn !== 0 ? (<Fragment>
          {/* <Route path="/start/:questionBankId/:userId" element={<StartingCountdown />} /> */}
          <Route path="/:questionBankId/:courseId/:userId" element={<StartingCountdown />} />
        </Fragment>
        ) : (<Fragment>
          {/* <Route path="/start/:questionBankId/:userId" element={<Quiz />} /> */}
          <Route path="/:questionBankId/:courseId/:userId" element={<Quiz />} />
        </Fragment>
        )}
        <Route path='/login' element={<Login />} />
        <Route path='/TestDesign' element={<TestDesign />} />
        <Route path='/admin' element={<Admin />}>
          <Route path='/admin/Atg/Assessment' element={<AtgAssignment />} />
          <Route path='/admin/AssessmentQuestionbanks' element={<Collections />} />
          <Route path='/admin/Attempts' element={<Attempts />} />
          <Route path='/admin/UsersReport' element={<UsersReport />} />
          <Route path='/admin/UsersReport/:UserId' element={<UserReportData />} />
          <Route path='/admin/AssessmentQuestionbanks/AddAssessmentQuestionbank' element={<AddCollection />} />
          <Route path='/admin/AssessmentQuestionbanks/UpdateAssessmentQuestionbank/:cbId' exact element={<UpdateCollection />} />
          <Route path='/admin/CreateAssessment/:cbId' exact element={<CreateAssessment />} />
          <Route path='/admin/EditAssessment/:cbId/:templateId' exact element={<CreateAssessment />} />
          <Route path='/admin/Assessment/GenerateAssessment/:AId' exact element={<GenerateAssessment />} />
          <Route path='/admin/Assessment/SendAssessment' element={<SendAssessment />} />
          <Route index element={<Assignment />} />
          <Route path='/admin/add_Assignment' element={<AddAssignment />} />
          <Route path='/admin/update_Assignment/:qbId' exact element={<UpdateAssignment />} />
        </Route>
        <Route path='/addFrom/:id' element={<AddFrom />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;