import React, { useState, Fragment } from "react";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input } from "antd";
import { Form } from "react-bootstrap";
import { useAddAssessmentMutation } from "../../redux/Collections/CollectionsApi";
import Header from "../Header";

const AddCollection = () => {
  const [imgAnswer, setimgAnswer] = useState(false);
  console.log(imgAnswer);
  const [ColInfo, setColInfo] = useState([{ collection_name: "" }]);
  const [AddColl, { isLoading }] = useAddAssessmentMutation();
  const navigate = useNavigate();
  const [quizinfo, setquizinfo] = useState([
    {
      section_name: "",
      questions: [],
    },
  ]);
  const heandalQuizinfochange = (event, index) => {
    let data = [...quizinfo];
    data[index].section_name = event.target.value;
    setquizinfo(data);
  };
  const heandalColInfochange = (event) => {
    let data = [...ColInfo];
    data[0][event.target.name] = event.target.value;
    setColInfo(data);
  };
  const [checkImageQues, setCheckImageQues] = useState(false);
  const heandalFromchange = (event, index, mainIndex) => {
    let data = [...quizinfo];
    data[mainIndex].questions[index][event.target.name] = event.target.value;
    if (data[mainIndex].questions[index].question_type === "text") {
      setCheckImageQues(true);
    } else if (data[mainIndex].questions[index].question_type === "code") {
      setCheckImageQues(false);
    }
    setquizinfo(data);
  };

  const handleFromAnswerchange = (event, answerIndex, qusIndex, mainIndex) => {
    if (quizinfo[mainIndex].questions[qusIndex].answer_type === "image") {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const string = reader.result.toString();
        let data = [...quizinfo];
        data[mainIndex].questions[qusIndex].answers[answerIndex].answer =
          string;
        setquizinfo(data);
      };
      reader.readAsDataURL(file);
    } else {
      let data = [...quizinfo];
      data[mainIndex].questions[qusIndex].answers[answerIndex].answer =
        event.target.value;
      setquizinfo(data);
    }
  };

  const handleFromAnswerChange1 = (event, answerIndex, qusIndex, mainIndex) => {
    let data = [...quizinfo];
    if (event.target.value === "true") {
      data[mainIndex].questions[qusIndex].answers[
        answerIndex
      ].is_correct = true;
    } else {
      data[mainIndex].questions[qusIndex].answers[
        answerIndex
      ].is_correct = false;
    }
    setquizinfo(data);
  };

  const AddQusFiled = (index) => {
    const data = [...quizinfo];
    const Newqus = {
      question_type: "",
      question_images: [],
      answer_type: "",
      answers: [],
      createdAt: Date.now(),
    };
    data[index].questions.push(Newqus);
    setquizinfo(data);
  };
  const checkQuestOption = (index) => {
    // AddQusFiled(index)
    let lastQuesIdx =
      quizinfo[index].questions > 1
        ? quizinfo[index].questions[quizinfo[index].questions.length - 2]
        : quizinfo[index].questions[quizinfo[index].questions.length - 1];
    let lastQuesAnsLen = lastQuesIdx?.answers?.length;
    if (lastQuesAnsLen >= 2 || lastQuesIdx === undefined) {
      AddQusFiled(index);
    } else {
      toast.info(
        "Please Select At Least Two Options For The Previous Question"
      );
    }
  };
  const RemoveQusFiled = (mainIndex, index) => {
    const newArray = [...quizinfo];
    newArray[mainIndex].questions.splice(index, 1);
    setquizinfo(newArray);
  };
  const RemoveSection = (index) => {
    const newArray = [...quizinfo];
    newArray.splice(index, 1);
    setquizinfo(newArray);
  };
  const AddOptions = (mainIndex, index) => {
    if (
      quizinfo[mainIndex].questions[index].answer_type &&
      quizinfo[mainIndex].questions[index].question_type
    ) {
      const data = [...quizinfo];
      const newOption = {
        answer: "",
        is_correct:
          data[mainIndex].questions[index].answers.find(
            (e) => e.is_correct === true
          ) === undefined
            ? true
            : false,
      };
      data[mainIndex].questions[index].answers.push(newOption);
      setquizinfo(data);
    } else {
      toast.info("Please Select The Option Type");
    }
  };
  const AddImageQuest = (mainIndex, index) => {
    const data = [...quizinfo];
    const newImg = {
      img: "",
    };
    data[mainIndex].questions[index].question_images.push(newImg);
    setquizinfo(data);
  };
  const handleImageQuestchange = (
    event,
    imgQuestIndex,
    qusIndex,
    mainIndex
  ) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const string = reader.result.toString();
      let data = [...quizinfo];
      data[mainIndex].questions[qusIndex].question_images[imgQuestIndex].img =
        string;
      setquizinfo(data);
    };
    reader.readAsDataURL(file);
  };
  const removeOption = (questionIndex, answerIndex, mainIndex) => {
    const data = [...quizinfo];

    const items = data[mainIndex].questions[questionIndex].answers.filter(
      (answer, idx) => {
        return idx !== answerIndex;
      }
    );

    data[mainIndex].questions[questionIndex].answers = items;
    setquizinfo(data);
  };
  const removeImgOption = (imQuestIndex, questionIndex, mainIndex) => {
    const data = [...quizinfo];

    const items = data[mainIndex].questions[
      questionIndex
    ].question_images.filter((answer, idx) => {
      return idx !== imQuestIndex;
    });

    data[mainIndex].questions[questionIndex].question_images = items;
    setquizinfo(data);
  };
  const checkQuestionsOptions = () => {
    let values = [];
    for (const quiz of quizinfo) {
      for (const quest of quiz.questions) {
        if (quest.answers.find((e) => e.is_correct === true) === undefined) {
          values.push(false);
        } else if (quest.answers.length < 2) {
          values.push(quest.answers.length);
        } else if (quest.question_type === "Question type ?") {
          values.push(quest.question_type);
        } else if (quest.correct_answer_description === "") {
          values.push("empty_correct_answer");
        }
      }
    }
    values = values.find(
      (e) =>
        e === 0 ||
        e === "Question type ?" ||
        e === false ||
        e === "empty_correct_answer"
    );
    return values;
  };
  const onSubmit = (event) => {
    event.preventDefault();

    const LastObject = {
      collection_name: ColInfo[0].collection_name,
      sections: quizinfo,
    };
    console.log(LastObject);
    if (checkQuestionsOptions() === undefined) {
      // Send a POST request
      AddColl(LastObject)
        .unwrap()
        .then((res) => {
          setquizinfo([
            {
              section_name: "",
              questions: [],
            },
          ]);
          toast.success("Assessment Questionbank added successfully");
          navigate(`/admin/AssessmentQuestionbanks`);
          setquizinfo([]);
          document.querySelector("[name='collection_name']").value = "";
          console.log(res);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          toast.error("Error Adding Assessment Questionbank");
        });
    } else if (checkQuestionsOptions() === "Question type ?") {
      toast.error("Please Select Question Type For Each Question");
    } else if (checkQuestionsOptions() === false) {
      toast.error("Please Select One Correct Answer for Each Question");
    } else {
      toast.error("Please Set Options For Question");
    }
  };

  const AddCollection = () => {
    const newSection = {
      section_name: "",
      questions: [],
    };
    const newCol = [...quizinfo, newSection];
    setquizinfo(newCol);
    // console.log(quizinfo);
  };
  const HandleImageAnswer = (mainIndex, qusIndex) => {
    const data = [...quizinfo];
    setimgAnswer(!imgAnswer);
    console.log("checkbox", imgAnswer);
    data[mainIndex].questions[qusIndex].question_images = [];
  };

  const handleCorrectAnswerImage = (
    event,
    qusIndex,
    mainIndex
  ) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const string = reader.result.toString();
      const data = [...quizinfo];
      data[mainIndex].questions[qusIndex].correct_answer_image = { img:string }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Fragment>
      <Header
        title={`Adding Assessment Question Bank`}
        secTitle={`Create Questionbank`}
        path={"/admin/AssessmentQuestionbanks"}
      />
      <div className="assignment-adding-div mx-auto px-4 mt-4 shadow ">
        <div>
          <Form.Label className="fw-bold fs-6  p-2">
            Name <span className="text-danger">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            className="border border-2 border-secondary p-2 rounded mt-2 mb-3 w-50 shadow-sm"
            name="collection_name"
            onChange={(event) => heandalColInfochange(event)}
          />
        </div>
        <div className="flex justify-between align-items-center"></div>

        {quizinfo.map((e, mainIndex) => {
          return (
            <Fragment key={mainIndex}>
              <div className="flex justify-between align-items-center">
                <h5 className="py-2 px-3 my-3 border border-1 rounded  fw-bold">
                  Section {mainIndex + 1}
                </h5>
                <button
                  type="button"
                  class="btn btn-danger rounded-pill   fs-6 shadow-sm"
                  onClick={() => RemoveSection(mainIndex)}
                >
                  Remove Section
                </button>
              </div>

              <div className="">
                <Form.Label className="fw-bold  fs-6">
                  Section Name <span className="text-danger">*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="border border-2 border-secondary p-2 rounded mt-2 mb-3 w-50 shadow-sm"
                  placeholder="Enter Name"
                  name="section_name"
                  onChange={(event) => heandalQuizinfochange(event, mainIndex)}
                />
              </div>
              <form onSubmit={onSubmit}>
                {e.questions?.map((form, index) => (
                  <div className="mb-3 mt-4" key={index}>
                    <div className="flex justify-between align-items-center">
                      <h3 className="py-2 px-3 border border-1 rounded fs-6 fw-bold">
                        Question No : {index + 1}
                      </h3>
                      <div className="flex justify-end my-3">
                        <button
                          type="button"
                          class="btn btn-outline-danger rounded-pill   fs-6 shadow-sm"
                          onClick={() => RemoveQusFiled(mainIndex, index)}
                        >
                          Remove Question
                        </button>
                      </div>
                    </div>
                    <div className="flex mt-3">
                      <select
                        className="select rounded shadow-sm border border-1 w-50 p-2 me-1 mb-4 shadow-md border-secondary"
                        name="question_type"
                        onChange={(event) =>
                          heandalFromchange(event, index, mainIndex)
                        }
                      >
                        <option defaultValue={true}>Question type ?</option>
                        <option>text</option>
                        <option>code</option>
                      </select>
                      <select
                        className="select rounded shadow-sm border border-1 ms-1 p-2 w-50 mb-4 shadow-md border-secondary"
                        name="answer_type"
                        onChange={(event) =>
                          heandalFromchange(event, index, mainIndex)
                        }
                      >
                        <option defaultValue={true}>Answer type ?</option>
                        <option>text</option>
                        <option>image</option>
                        <option>code</option>
                      </select>
                    </div>
                    {/* <input type="text" placeholder={`Question`} className="input p-2 rounded-none input-bordered w-full mb-[25px] drop-shadow-md" name='body' onChange={event => heandalFromchange(event, index)}
                            /> */}

                    <textarea
                      rows="2"
                      cols="90"
                      type="text"
                      placeholder={`Question`}
                      className="input p-2  border border-1 w-100 mb-3 shadow-sm border-secondary"
                      name="body"
                      onChange={(event) =>
                        heandalFromchange(event, index, mainIndex)
                      }
                    />
                    {checkImageQues && (
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Form.Check
                          type="checkbox"
                          label={`Include Images in Question`}
                          className="fs-5"
                          onClick={() => HandleImageAnswer(mainIndex, index)}
                        />
                      </div>
                    )}
                    {form?.question_images.map((img, imQuestIndex) => {
                      return (
                        <Fragment key={imQuestIndex}>
                          <div className="d-flex">
                            <Input
                              type="file"
                              placeholder="Image Question"
                              className="input p-2 my-2 rounded-none input-bordered w-full  drop-shadow-md  border-secondary"
                              name={"question_image"}
                              onChange={(event) =>
                                handleImageQuestchange(
                                  event,
                                  imQuestIndex,
                                  index,
                                  mainIndex
                                )
                              }
                            />
                            <button
                              type="button"
                              class="btn"
                              onClick={() =>
                                removeImgOption(imQuestIndex, index, mainIndex)
                              }
                            >
                              <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                            </button>
                          </div>
                        </Fragment>
                      );
                    })}
                    <div>
                      {imgAnswer && (
                        <button
                          type="button"
                          class="btn btn-primary rounded-pill my-3  fs-6 shadow-sm me-2"
                          onClick={() => AddImageQuest(mainIndex, index)}
                        >
                          Add Image Question{" "}
                        </button>
                      )}
                    </div>
                    <div className="d-flex justify-content-end my-3 ">
                      <div className="">
                        <button
                          type="button"
                          class="btn btn-primary rounded-pill   fs-6 shadow-sm   me-3 px-2"
                          onClick={() => AddOptions(mainIndex, index)}
                        >
                          Add Option{" "}
                        </button>
                      </div>
                    </div>
                    {form?.answer_type === "text" && (
                      <>
                        {form?.answers?.map((value, idx) => {
                          return (
                            <div key={idx} className="flex align-center mt-2">
                              <select
                                className="select rounded-none select-bordered drop-shadow-md px-1"
                                name={"is_correct"}
                                onChange={(event) =>
                                  handleFromAnswerChange1(
                                    event,
                                    idx,
                                    index,
                                    mainIndex
                                  )
                                }
                              >
                                <Fragment>
                                  <option defaultValue={true}>false</option>
                                  <option>true</option>
                                </Fragment>
                              </select>
                              <Input
                                type="text"
                                placeholder="option"
                                className="input p-2 option_inp rounded-none input-bordered w-full mb-[25px] drop-shadow-md ml-2 border-secondary"
                                name={"answer"}
                                onChange={(event) =>
                                  handleFromAnswerchange(
                                    event,
                                    idx,
                                    index,
                                    mainIndex
                                  )
                                }
                              />
                              <button
                                type="button"
                                class="btn"
                                onClick={() =>
                                  removeOption(index, idx, mainIndex)
                                }
                              >
                                <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {form?.answer_type === "code" && (
                      <Fragment>
                        {form?.answers?.map((value, idx) => {
                          return (
                            <div key={idx} className="flex align-center mt-2">
                              <select
                                className="select  border border-1 shadow-md px-1 h-[46px] border-secondary"
                                name={"is_correct"}
                                onChange={(event) =>
                                  handleFromAnswerChange1(
                                    event,
                                    idx,
                                    index,
                                    mainIndex
                                  )
                                }
                              >
                                <Fragment>
                                  <option defaultValue={true}>false</option>
                                  <option>true</option>
                                </Fragment>
                              </select>
                              <textarea
                                rows="1"
                                cols="90"
                                type="text"
                                placeholder="option"
                                className="input p-2 option_inp  input-bordered w-100 mb-4 shadow-md mb-0 ms-2 border-secondary"
                                name={"answer"}
                                value={value.answer}
                                onChange={(event) =>
                                  handleFromAnswerchange(
                                    event,
                                    idx,
                                    index,
                                    mainIndex
                                  )
                                }
                              />
                              <button
                                type="button"
                                class="btn"
                                onClick={() =>
                                  removeOption(index, idx, mainIndex)
                                }
                              >
                                <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                              </button>
                            </div>
                          );
                        })}
                      </Fragment>
                    )}
                    {form?.answer_type === "image" && (
                      <>
                        {form?.answers?.map((value, idx) => {
                          return (
                            <div className="flex align-center mt-2" key={idx}>
                              <select
                                className="select rounded-none select-bordered drop-shadow-md px-1 border-secondary"
                                name={"is_correct"}
                                onChange={(event) =>
                                  handleFromAnswerChange1(
                                    event,
                                    idx,
                                    index,
                                    mainIndex
                                  )
                                }
                              >
                                <Fragment>
                                  <option defaultValue={true}>false</option>
                                  <option>true</option>
                                </Fragment>
                              </select>
                              <Input
                                type="file"
                                placeholder="option"
                                className="input p-2 option_inp rounded-none input-bordered w-full mb-[25px] drop-shadow-md ml-2 border-secondary"
                                name={"answer"}
                                onChange={(event) =>
                                  handleFromAnswerchange(
                                    event,
                                    idx,
                                    index,
                                    mainIndex
                                  )
                                }
                              />

                              <button
                                type="button"
                                class="btn"
                                onClick={() =>
                                  removeOption(index, idx, mainIndex)
                                }
                              >
                                <i class="fa-solid fa-trash mx-2 text-danger fs-4"></i>
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {form?.answers.length >= 2 && (
                      <Fragment>
                        <Form.Label className="fs-6 mt-3">
                          Correct Answer Description :
                        </Form.Label>
                        <textarea
                          rows="2"
                          cols="90"
                          type="text"
                          placeholder={`Correct Answer Description`}
                          className="input p-2  border border-1 w-100 mb-1 shadow-sm border-secondary mt-3"
                          name="correct_answer_description"
                          onChange={(event) =>
                            heandalFromchange(event, index, mainIndex)
                          }
                        />
                        <Form.Label className="fs-6 mt-3 mb-2">
								Correct Answer Image
							</Form.Label>
						
                <Input
                type="file"
                placeholder="option"
                className="input p-2 input-bordered w-100 mb-4 shadow-md border-secondary rounded"
                name={"correct_answer_image"}
                onChange={(event) =>
                  handleCorrectAnswerImage(
                    event,
                    index,
                    mainIndex
                  )
                }
              />
							
                      </Fragment>
                    )}


                  </div>
                ))}
              </form>

              <div className="flex justify-end mt-4 pb-4">
                <button
                  type="button"
                  class="btn btn-primary rounded-pill me-3  px-3 py-2 shadow-sm"
                  onClick={AddCollection}
                >
                  Add Section +
                </button>
                <button
                  className="btn btn-outline-secondary rounded-pill me-3  px-3 py-2 shadow-sm"
                  onClick={() => checkQuestOption(mainIndex)}
                >
                  Add Question{" "}
                  <BsPlusSquareDotted className="inline fs-6 mb-1 ml-2" />
                </button>
              </div>
            </Fragment>
          );
        })}
        {quizinfo.length > 0 ? (
          <>
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button"
                class="btn btn-primary rounded-pill me-3  px-3 py-2 shadow-sm"
                onClick={onSubmit}
              >
                {isLoading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default AddCollection;
