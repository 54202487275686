
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetCollectionsQuery } from "../../redux/Collections/CollectionsApi.js"
import Header from '../Header.jsx'
import TestTable from '../TestTable/TestTable.js';
export default function Collections() {
    const [page, setpage] = useState(1)
    const [limit, setlimit] = useState(10)
    const [search, setsearch] = useState("")
    const { data, isLoading } = useGetCollectionsQuery({ page, limit, search });
    const [toload, setToload] = useState(false)
    const collections = data?.results
    const lenght = data?.total_pages;
    let sear = "";
    const test = (event) => {
        sear = event.target.value
        if (sear === "") {
            setsearch(sear)
        }
    }
    console.log(limit);
    console.log(data)

    const handleSearch = () => {
        setsearch(sear)
        setlimit(10)
    }

    return (
        <Fragment>
            <Header title={`Assessments QuestionBanks`} />
            <div className="mx-auto px-4 mb-2">
                {!isLoading && !toload ?
                    <Fragment>
                        <div className="modal modal-bottom sm:modal-middle">
                            <div className="modal-box text-center">
                                <h3 className="font-bold text-lg text-[#f5365c] ">Wait Be Care Full !</h3>
                                <p className="py-4 font-semibold">Do you want to <span className="text-[#f5365c] mx-1">Delete</span>all the collections ?</p>
                                <div className="flex justify-center">
                                    <label htmlFor="assignment-delete-model" className="modal-btn">Clear</label>
                                    <label htmlFor="assignment-delete-model" className="modal-delete-btn">Delete</label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end align-items-center mt-4'>
                            <div className="d-flex me-2">
                                <input class="form-control w-75 me-2 rounded h-min " onChange={test} type="search" placeholder="Search by Name" aria-label="Search" />
                                <button className='btn btn-primary' onClick={() => handleSearch()}>Search</button>
                            </div>
                            <Link to='AddAssessmentQuestionbank'><button className="btn btn-primary">Create New</button></Link>
                        </div>
                        <TestTable limit={limit} collections={collections} page={page} setlimit={setlimit} lenght={lenght} setpage={setpage} />
                    </Fragment> : <div className='vh-100 d-flex justify-content-center align-items-center'>
                        <i className="fas fa-spinner fa-spin fa-3x"></i>
                    </div>
                }
            </div>


        </Fragment>
    )
}

