import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const AssessmentApi = createApi({
    tagTypes: ['Assessment'],
    reducerPath: 'sendAssessmentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
    }),
    endpoints: (builder) => ({
        getAssessment: builder.query({
            query: () => '/users/generate-links',
            providesTags: ['Post'],
        }),
        generateAssessment: builder.mutation({ query: (payload) => ({ url: 'users/generate-links', method: 'POST', body: payload }) }),
        sendAssessment: builder.mutation({ query: (payload) => ({ url: 'users/send-links', method: 'POST', body: payload }) }),
        addAssessmentTemplate: builder.mutation({ query: (payload) => ({ url: 'assessmentTemplate/add', method: 'POST', body: payload }), invalidatesTags: ['Assessment'] }),
        editAssessmentTemplate: builder.mutation({ query: (payload) => ({ url: `assessmentTemplate/${payload._id}/update`, method: 'PUT', body: payload }), invalidatesTags: ['Assessment'] }),
        deleteAssessmentTemplate: builder.mutation({ query: (_id) => ({ url: `assessmentTemplate/${_id}/delete`, method: 'delete' }), invalidatesTags: ['Assessment'] }),
        getAssessmentTemplate: builder.query({ query: ({ page, limit, search }) => `/assessmentTemplate/?page=${page}&limit=${limit}&title=${search}`, providesTags: ['Assessment'] }),
        getAssessmentTemplateById: builder.query({ query: ({ id }) => `/assessmentTemplate/?id=${id}`, providesTags: ['Assessment'] }),
        generateAssessmentTemplate: builder.query({ query: (questionBankId) => `/standaloneAssessment/generate?id=${questionBankId}` }),
        getCreateAssessment: builder.query({
            query: () => 'questionBank/add',
            providesTags: ['Post'],
        }),
        createAssessment: builder.mutation({ query: (payload) => ({ url: 'questionBank/add', method: 'POST', body: payload }) }),
    })
})

export const { useAddAssessmentTemplateMutation, useEditAssessmentTemplateMutation,useGetAssessmentTemplateByIdQuery ,useGenerateAssessmentMutation, useSendAssessmentMutation, useGetAssessmentQuery, useGetCreateAssessmentQuery, useGetAssessmentTemplateQuery, useGenerateAssessmentTemplateQuery, useCreateAssessmentMutation, useDeleteAssessmentTemplateMutation } = AssessmentApi;
